import React, { Component } from 'react';

export default class NavBar extends Component {

    state = {

    }

    render() {
        return (
            <div>
                <div className="topNavBarContainer">
                    <span className="topNavBarItem">PLUG'HEUR</span>
                    <span className="topNavBarItem">{this.props.itemGroupSelected}</span>
                    <img className="topNavBarItem img" src="/img/Icons/AdminIconNavBar.png" alt={"User Icon"} />
                </div>
            </div>
        )
    }
}

