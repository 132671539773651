import React from "react";

export default class CreateNewUsers extends React.PureComponent {

    state = {
        name: undefined,
        surname: undefined,
        email: undefined,
        password: undefined,
        phone_num: undefined,
        role: undefined,
        verified: false
    }

    // constructor(props) {
    //     super(props)
    // }
    registerUser = async () => {
        if(this.state.role === "venue"){ this.setState({ verified: true }) }
        if(!this.state.name ||!this.state.email || !this.state.password ||!this.state.phone_num || !this.state.role)
        {alert("All fields must be filled in."); return}

        await fetch('https://plugheur.fr/api/v4/registers', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + this.props.options.token,
            'Access-Control-Allow-Credentials': true
          },
          body: JSON.stringify(this.state),
          credentials: 'omit'
        }).then((res) => res.json())
          .then((data) => {
              
            if (data.success) {
                alert("User Created.")
            } else {
              alert(data.error)
            }
          })
      }

    render() {
        return (
            <div className="mainInfoContainer" >
                <div style={{ width: "80%", margin: "0px auto", marginTop: "10%" }}>
                    <h2>New User Profile</h2>
                    <div className="inputContainer">
                        <span>Name</span>
                        <input
                            type="text"
                            placeholder="Venue Name"
                            width={100}
                            height={50}
                            onChange={(event) => { this.setState({ name: event.target.value }) }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Surname</span>
                        <input
                            type="text"
                            placeholder="Venue Last Name"
                            width="auto"
                            height={50}
                            onChange={(event) => { this.setState({ surname: event.target.value }) }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Email</span>
                        <input
                            type="text"
                            placeholder="venue@email.com"
                            width="auto"
                            height={50}
                            onChange={(event) => { this.setState({ email: event.target.value }) }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Phone No</span>
                        <input
                            type="text"
                            placeholder="+33 123 456 0987"
                            width={100}
                            height={50}
                            onChange={(event) => { this.setState({ phone_num: event.target.value }) }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Password</span>
                        <input
                            type="text"
                            placeholder="Enter password"
                            width={100}
                            height={50}
                            onChange={(event) => { this.setState({ password: event.target.value }) }}>
                        </input>
                    </div>
                    <div className="cabinetInfoSelectTime">
                        <span>Account Type</span>
                        <select name="cars" id="cars" form="carform" onChange={(event) => { this.setState({ role: event.target.value }) }}>
                            <option value={"Select"}>Select</option>
                            <option value={"user"}>user</option>
                            <option value={"venue"}>venue</option>
                        </select>
                    </div>
                    <div className="cabinetInfoUpdateButtons">
                        <button onClick={()=>{this.registerUser()}} className="userEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Create</span>
                        </button>
                        <button onClick={() => this.props.back()} className="userEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Back</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}