import React, { Component } from 'react';

export default class SideNavBar extends Component {
    state = {

    }

    returnImgPath = () => {
        var path = this.props.loggedUser.name.toString().toLowerCase()

        if(path !== "louis" && path !== "arthur") path = "emile"

        return <img src={`/img/usersAvatar/${path}.jpg`} alt={"User Icon"} />
    }

    render() {
        return (
            <div className="sidenav">
                <div className="topNavBarItem img user">
                    { this.returnImgPath() }
                    <p>{this.props.loggedUser.name.toString() + " " + this.props.loggedUser.surname.toString()}</p>
                </div>
                <div className="sidenavItem">
                    <img src="/img/Icons/dashboard_icon.png" alt={"Nav Icon"} />
                    <span onClick={() => { this.props.setPropItem("Dashboard") }}>Dashboard</span>
                </div>
                <div className="sidenavItem">
                    <img src="/img/Icons/users_icon.png" alt={"Nav Icon"} />
                    <span onClick={() => { this.props.setPropItem("Users") }}>Users</span>
                </div>
                <div className="sidenavItem">
                    <img src="/img/Icons/rentals_icon.png" alt={"Nav Icon"} />
                    <span onClick={() => { this.props.setPropItem("Rentals") }}>Rentals</span>
                </div>
                <div className="sidenavItem">
                    <img src="/img/Icons/stations_icon.png" alt={"Nav Icon"} />
                    <span onClick={() => { this.props.setPropItem("Stations") }}>Stations</span>
                </div>
                <div className="sidenavItem">
                    <img src="/img/Icons/promocode_icon.png" alt={"Nav Icon"} />
                    <span onClick={() => { this.props.setPropItem("Promo Code") }}>Promo Code</span>
                </div>
                <div className="sidenavItem">
                    <img src="/img/Icons/review_icon.png" alt={"Nav Icon"} />
                    <span>Review</span>
                </div>
                <img className="topNavBarItem img logo" src="/img/plugheur_logo_nav.png" alt={"Plug'Heur Logo"} />
            </div>
        )
    }
}
