import React, { Component } from 'react';

export default class Creatediscounts extends Component {
    state = {
        showTable: false,
        graphData: undefined,
        statType: "monthly",
        maxUsers: 0,
        discounts: [],
        code: "",
        credits: 0,
        note: "",
        discountCondition: "Unlimited Use"
    }

    change = (evt) => {
        this.setState({
            discountCondition: evt.target.value
        });
    }

    createDiscount = async () => {
        if (this.state.code !== "" && this.state.credits !== "") {
            await fetch("https://plugheur.fr/api/v4/check/discounts", {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.options.token
                },
                body: JSON.stringify({
                    code: this.state.code,
                    credits: parseFloat(this.state.credits),
                    discountCondition: this.state.discountCondition,
                    note: this.state.note.toString(),
                    maxUsers: this.state.maxUsers
                }),
                credentials: "omit"
            }).then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        alert("Discount created")
                        this.props.updateList()
                    } else {
                        alert("Discount creation failed.")
                    }
                })
        } else {
            alert("Please fill in code and credits")
        }
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <h3 style={{ marginLeft: 60, marginTop: 60 }}>Promo Code</h3>
                <div style={{ padding: "10px 60px" }}>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>FROM</span>
                        <input
                            type="text"
                            placeholder={"FROM"}
                            width={100}
                            height={50}
                            onChange={() => { }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>TO</span>
                        <input
                            type="text"
                            placeholder={"TO"}
                            width={100}
                            height={50}
                            onChange={() => { }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Type</span>
                        <select 
                        name="promoConditions" 
                        id="promoConditions" 
                        onChange={(evt) => {this.setState({discountCondition: evt.target.value});}} 
                        value={this.state.discountCondition}
                        >
                            <option value="Unlimeted Use">Unlimeted Use</option>
                            <option value="First Use">First Use</option>
                            <option value="Single Use">Single Use</option>
                            <option value="Limited Use">Limited Use</option>
                        </select>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Code</span>
                        <input
                            type="text"
                            placeholder={"Code"}
                            width={100}
                            height={50}
                            onChange={(evt) => {this.setState({code: evt.target.value});}}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Credits/Promo</span>
                        <input
                            type="text"
                            placeholder={"Credits/Promo"}
                            width={100}
                            height={50}
                            onChange={(evt) => {this.setState({credits: evt.target.value });}}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>maxUsers</span>
                        <input
                            type="text"
                            placeholder={"Number of Max users (Ignore if unlimeted)"}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ maxUsers: evt.target.value }) }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Note</span>
                        <input
                            type="text"
                            placeholder={"Note"}
                            width={100}
                            height={50}
                            onChange={(evt) => {this.setState({note: evt.target.value});}}>
                        </input>
                    </div>
                </div>
                <button style={{ marginLeft: "82%" }} onClick={this.createDiscount} className="cabinetInfoEditButton">
                    <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                    <span>Create Discount</span>
                </button>
                <h3 style={{ marginLeft: 60, marginTop: 10 }}>Resturaunt Discount Code</h3>
                <div style={{ padding: "10px 60px" }}>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>FROM</span>
                        <input
                            type="text"
                            placeholder={"FROM"}
                            width={100}
                            height={50}
                            onChange={() => { }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>TO</span>
                        <input
                            type="text"
                            placeholder={"TO"}
                            width={100}
                            height={50}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Resturaunt</span>
                        <input
                            type="text"
                            placeholder={"Resturaunt/Station"}
                            width={100}
                            height={50}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Title</span>
                        <input
                            type="text"
                            placeholder={"Title"}
                            width={100}
                            height={50}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Description</span>
                        <input
                            type="text"
                            placeholder={"Description"}
                            width={100}
                            height={50}>
                        </input>
                    </div>
                </div>
                <button style={{ marginLeft: "82%" }} className="cabinetInfoEditButton">
                    <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                    <span>Create Discount</span>
                </button>
            </div>
        )
    }
}
