import React, { Component } from 'react';
import ItemSelection from './itemSelection';
import { ResponsiveBar } from '@nivo/bar'
import CreateNewUsers from './users/createNewUsers';
const MOMENT = require('moment');

export default class UserView extends Component {
    state = {
        showTable: false,
        graphData: undefined,
        createUser: false,
        statType: "monthly"
    }

    componentDidMount = () => {
        this.createData()
    }

    showLoader = () => {
        return (
            <div style={{ height: "100vh" }}>
                <div className="loader"></div>
            </div>
        )
    }

    TimeDiff = (startTime, endTime, format) => {
        startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
        endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
        return endTime.diff(startTime, format, true);
    }

    createData = async () => {
        if (this.state.statType === "monthly") {
            this.getMonthlyStat()
        }
    }

    getMonthlyStat = () => {
        var finalData = []
        var monthValue = ""
        var countusers = 0
        var users = this.props.usersView

        for (var x = 1; x <= 12; x++) {
            if (x === 1) {
                monthValue = "Jan"
            } else if (x === 2) {
                monthValue = "Feb"
            } else if (x === 3) {
                monthValue = "Mar"
            } else if (x === 4) {
                monthValue = "Apr"
            } else if (x === 5) {
                monthValue = "May"
            } else if (x === 6) {
                monthValue = "Jun"
            } else if (x === 7) {
                monthValue = "Jul"
            } else if (x === 8) {
                monthValue = "Aug"
            } else if (x === 9) {
                monthValue = "Sep"
            } else if (x === 10) {
                monthValue = "Oct"
            } else if (x === 11) {
                monthValue = "Nov"
            } else {
                monthValue = "Dec"
            }

            countusers = 0
            for (var y in users) {
                var month = new Date(users[y].createdAt).getMonth() + 1
                if (month === x && new Date(users[y].createdAt).getFullYear() == new Date().getFullYear()) {
                    countusers++
                }
            }

            finalData.push({
                "month": monthValue,
                "value": countusers
            })

            if (x === 12) {
                this.setState({ graphData: finalData })
            }
        }
    }

    MyResponsiveBar = () => {
        return (
            <div className="mainInfoContainer">
                <div className="tableHeader">
                    <div onClick={() => { this.setState({ showTable: true }) }} className="csvExportButton">
                        <img src="/img/Icons/export_content.png" alt={"User Icon"} />
                        <p>Users</p>
                    </div>
                    <div onClick={() => { this.setState({ createUser: true }) }} className="csvExportButton">
                        <img src="/img/Icons/export_content.png" alt={"User Icon"} />
                        <p>Create</p>
                    </div>
                </div>
                <div className="graphStyle">
                    <ResponsiveBar
                        data={this.state.graphData}
                        indexBy="month"
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        colors={{ scheme: 'dark2' }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'MONTH',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'NUMBER',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div>
            </div>
        )
    }

    returnTable = () => {
        return (
            <div className="mainInfoContainer" >
                <div className="tableHeader">
                    <h2 id='title' style={{ margin: "auto" }}>Users</h2>
                    <a href="./users.csv">
                        <div className="csvExportButton" onClick={this.exportCSV}>
                            <img src="/img/Icons/export_content.png" alt={"User Icon"} />
                            <p>EXPORT</p>
                        </div>
                    </a>
                    <input
                        type="text"
                        placeholder={"Search"}
                        width={100}
                        height={50}
                        onChange={this.props.search}
                        style={{
                            margin: "auto",
                            border: "1px solid #e3e1e1",
                            borderRadius: "6px",
                        }}>
                    </input>
                </div>
                <table id='students'>
                    <tbody>
                        <tr>
                            <th key={Math.random()} onClick={() => { this.props.sort("users", "name", 1) }}>Name</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("users", "email", 1) }}>Email</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("users", "phone_num", 1) }}>Phone Number</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("users", "date", 1) }}>Date Of Registration</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("users", "card", 1) }}>Card</th>
                        </tr>
                        {
                            this.props.users.map((item, index) => (
                                <ItemSelection key={index} itemRetrived={"Users"} item={item} options={this.props.options} selectedItem={this.selectedUser} />
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    selectedUser = (userId) => {
        this.props.selectedItem(userId)
    }

    render() {
        if (this.state.graphData === undefined) {
            return (
                this.showLoader()
            )
        } else if(this.state.createUser){
            return( <CreateNewUsers options={this.props.options} back={() => { this.setState({ createUser: false }) }}/>)
        }else if (this.state.showTable) {
            return (
                this.returnTable()
            )
        } else if (!this.state.showTable && this.state.graphData !== undefined) {
            return (
                this.MyResponsiveBar()
            )
        }
    }
}