import React from "react";
import Moment from 'moment';

export default class UserHistory extends React.PureComponent {

    state = {
        userHistory: undefined,
        currentOnGoing: undefined,
        declined: undefined,
        fullHistory: undefined,
        viewBanksInfo: true,
        viewCurrentRentals: true,
        viewdeclinedRentals: true,
        viewOldRentals: true,
        historyView: 1,
        historyViewTitle: "Power Bank Usage",
        search: "",
        completedHistory: 0
    }

    constructor(props) {
        super(props)
        Moment.locale('fr');
        this.getUserHistory()
    }

    getUserHistory = async () => {
        await fetch('https://plugheur.fr/api/v4/check/user_history', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data.succes) {
                    // var payload = data.data
                    var current = []
                    var declined = []
                    var old = []
                    // console.log(data)

                    if (this.props.history) {
                        for (var y in data.data) {
                            if (data.data[y].history.location_returned === "On Going" && data.data[y].history.UserID === this.props.history) {
                                current.push(data.data[y])
                            } else if (data.data[y].history.location_returned === "Declined" && data.data[y].history.UserID === this.props.historyv) {
                                declined.push(data.data[y])
                            } else if (data.data[y].history.location_returned !== "On Going" && data.data[y].history.UserID === this.props.history) {
                                old.push(data.data[y])
                            }
                        }
                    } else {
                        for (var x in data.data) {
                            if (data.data[x].history.location_returned === "On Going") {
                                current.push(data.data[x])
                            } else if (data.data[x].history.location_returned === "Declined") {
                                declined.push(data.data[x])
                            } else {
                                old.push(data.data[x])
                            }
                        }
                    }

                    this.setState({ userHistory: old, currentOnGoing: current, declined: declined, fullHistory: data.data, completedHistory: data.countComplete })
                } else {
                    alert("39" + data.error)
                }

            })
    }

    powerBankUsage = () => {
        var cabinets = this.state.fullHistory
        var checkedBanks = []

        if (!this.state.viewBanksInfo) { return null }

        return (
            cabinets.map(cabinetHistory => {
                var usedCabinet = undefined
                var found = false

                if (checkedBanks.length === 0 && cabinetHistory.history.location_returned !== "On Going") {
                    checkedBanks.push(cabinetHistory.history.PowerBankID)
                    usedCabinet = cabinetHistory
                } else if (checkedBanks.length !== 0 && cabinetHistory.history.location_returned !== "On Going") {
                    for (var h in checkedBanks) {
                        if (checkedBanks[h] === cabinetHistory.history.PowerBankID) {
                            found = true
                        }
                    }

                    if (!found) {
                        checkedBanks.push(cabinetHistory.history.PowerBankID)
                        usedCabinet = cabinetHistory
                    }
                }
                if (usedCabinet !== undefined) {
                    return (
                        <tr key={usedCabinet.history.PowerBankID}>
                            <td>{usedCabinet.history.PowerBankID}</td>
                            <td>{usedCabinet.bankHistory} times</td>
                            <td>{usedCabinet.history.location_returned}</td>
                            <td>{usedCabinet.history.slot}</td>
                        </tr>
                    )
                } else { return null }
            })
        )
    }

    returnItemSelected = (itemSelected, type, view) => {
        if (!this.state[view]) { return null }

        if (this.state[type].length > 0) {
            return (
                this.state[type].map((item, index) => (
                    <ItemSelectionU itemRetrieved={itemSelected} key={index} item={item} options={this.props.options} />
                ))
            )
        } else {
            return (
                <tr key={Math.random()}>{"No Rentals List"}</tr>
            )
        }
    }

    search = evt => {
        this.setState({ search: evt.target.value.toString() }, () => {
            // var newDeviceList = []


        })
    }

    sort = (list, value, type) => {
        var sortedList = []
        var oldList = this.state[list]

        if (list === "fullHistory") {
            if (value !== "used") {
                sortedList = oldList.sort((a, b) => {
                    return (
                        a["history"][value] > b["history"][value] ? (1 * type) : (-1 * type)
                    )
                })
            } else {
                sortedList = oldList
            }

            this.setState({ fullHistory: sortedList })
        } else {
            if (value === "email") {
                sortedList = oldList.sort((a, b) => {
                    return (
                        a[value] > b[value] ? (-1 * type) : (1 * type)
                    )
                })
            } else if (!value.includes("date")) {
                sortedList = oldList.sort((a, b) => {
                    return (
                        a["history"][value] > b["history"][value] ? (-1 * type) : (1 * type)
                    )
                })
            } else {
                sortedList = oldList
            }

            if (list === "currentOnGoing") {
                this.setState({ currentOnGoing: sortedList, historyView: this.state.historyView }, () => {
                })
            } else if (list === "userHistory") {
                this.setState({ userHistory: sortedList, historyView: this.state.historyView })
            } else {
                this.setState({ declined: sortedList, historyView: this.state.historyView })
            }
        }
    }

    verifyView = () => {
        var cabinets = this.state.fullHistory
        var checkedBanks = []

        if (!this.state.viewBanksInfo) { return null }

        if (this.state.historyView === 1) {
            return (
                <tbody>
                    <tr>
                        <th key={Math.random()} onClick={() => { this.sort("fullHistory", "PowerBankID", 1) }}>Powerbank</th>
                        <th key={Math.random()} onClick={() => { this.sort("fullHistory", "used", 1) }}>Times Used</th>
                        <th key={Math.random()} onClick={() => { this.sort("fullHistory", "location_returned", 1) }}>Last Returned</th>
                        <th key={Math.random()} onClick={() => { this.sort("fullHistory", "slot", 1) }}>Slot</th>
                    </tr>
                    {
                        cabinets.map(cabinetHistory => {
                            var usedCabinet = undefined
                            var found = false

                            if (checkedBanks.length === 0 && cabinetHistory.history.location_returned !== "On Going") {
                                checkedBanks.push(cabinetHistory.history.PowerBankID)
                                usedCabinet = cabinetHistory
                            } else if (checkedBanks.length !== 0 && cabinetHistory.history.location_returned !== "On Going") {
                                for (var h in checkedBanks) {
                                    if (checkedBanks[h] === cabinetHistory.history.PowerBankID) {
                                        found = true
                                    }
                                }

                                if (!found) {
                                    checkedBanks.push(cabinetHistory.history.PowerBankID)
                                    usedCabinet = cabinetHistory
                                }
                            }

                            if (usedCabinet !== undefined) {
                                return (
                                    <tr key={usedCabinet.history.PowerBankID}>
                                        <td>{usedCabinet.history.PowerBankID}</td>
                                        <td>{usedCabinet.bankHistory} times</td>
                                        <td>{usedCabinet.history.location_returned}</td>
                                        <td>{usedCabinet.history.slot}</td>
                                    </tr>
                                )
                            } else { return null }
                        })
                    }
                </tbody>
            )
        } else if (this.state.historyView === 2) {
            return (
                <tbody>
                    <tr>
                        <th key={Math.random()} onClick={() => { this.sort("currentOnGoing", "email", 1) }}>User Email</th>
                        <th key={Math.random()} onClick={() => { this.sort("currentOnGoing", "location_Taken", 1) }}>Place Taken</th>
                        <th key={Math.random()} onClick={() => { this.sort("currentOnGoing", "date_createdAt", 1) }}>Time Taken</th>
                        <th key={Math.random()} onClick={() => { this.sort("currentOnGoing", "date_last_seen", 1) }}>Time Charged</th>
                        <th key={Math.random()} onClick={() => { this.sort("currentOnGoing", "amount_charged", 1) }}>Amount Charged</th>
                    </tr>
                    {
                        this.returnItemSelected("current", "currentOnGoing", "viewCurrentRentals")
                    }
                </tbody>
            )
        } else if (this.state.historyView === 3) {
            return (
                <tbody>
                    <tr>
                        <th key={Math.random()} onClick={() => { this.sort("userHistory", "email", 1) }}>User Email</th>
                        <th key={Math.random()} onClick={() => { this.sort("userHistory", "location_Taken", 1) }}>Place Taken</th>
                        <th key={Math.random()} onClick={() => { this.sort("userHistory", "date_createdAt", 1) }}>Time Taken</th>
                        <th key={Math.random()} onClick={() => { this.sort("userHistory", "location_returned", 1) }}>Place Returned</th>
                        <th key={Math.random()} onClick={() => { this.sort("userHistory", "date_last_seen", 1) }}>Time Returned</th>
                        <th key={Math.random()} onClick={() => { this.sort("userHistory", "amount_charged", 1) }}>Amount Charged</th>
                    </tr>
                    {
                        this.returnItemSelected("old", "userHistory", "viewOldRentals")
                    }
                </tbody>
            )
        } else if (this.state.historyView === 4) {
            return (
                <tbody>
                    <tr>
                        <th key={Math.random()} onClick={() => { this.sort("declined", "email", 1) }}>User Email</th>
                        <th key={Math.random()} onClick={() => { this.sort("declined", "location_Taken", 1) }}>Place Taken</th>
                        <th key={Math.random()} onClick={() => { this.sort("declined", "date_createdAt", 1) }}>Time Taken</th>
                        <th key={Math.random()} onClick={() => { this.sort("declined", "date_last_seen", 1) }}>Time Charged</th>
                        <th key={Math.random()} onClick={() => { this.sort("declined", "amount_charged", 1) }}>Amount Charged</th>
                    </tr>
                    {
                        this.returnItemSelected("declined", "declined", "viewdeclinedRentals")
                    }
                </tbody>
            )
        } else {
            return "Eish"
        }
    }

    controlButtonInfo = (button) => {
        if (button === 1) {
            return (
                <div className="mainControlButton">
                    <div className="mainControlButtonInfo" onClick={() => { this.setState({ historyView: 1, historyViewTitle: "Power Bank Usage" }) }}>
                        <div>{this.state.fullHistory.length}</div>
                        <div>Power Bank Usage</div>
                    </div>
                </div>
            )
        } else if (button === 2) {
            return (
                <div className="mainControlButton">
                    <div className="mainControlButtonInfo" onClick={() => { this.setState({ historyView: 2, historyViewTitle: "Current Rentals" }) }}>
                        <div>{this.state.currentOnGoing.length}</div>
                        <div>Current Rentals</div>
                    </div>
                </div>
            )
        } else if (button === 3) {
            return (
                <div className="mainControlButton">
                    <div className="mainControlButtonInfo" onClick={() => { this.setState({ historyView: 3, historyViewTitle: "Completed Rentals" }) }}>
                        <div>{this.state.completedHistory}</div>
                        <div>Completed Rentals</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="mainControlButton">
                    <div className="mainControlButtonInfo" onClick={() => { this.setState({ historyView: 4, historyViewTitle: "Declined Rentals" }) }}>
                        <div>{this.state.declined.length}</div>
                        <div>Declined Rentals</div>
                    </div>
                </div>
            )
        }
    }

    render = () => {
        if (this.state.userHistory !== undefined && this.state.userHistory !== undefined) {
            return (
                <div>
                    <div className="mainControlButtons">
                        {this.controlButtonInfo(1)}
                        {this.controlButtonInfo(2)}
                        {this.controlButtonInfo(3)}
                        {this.controlButtonInfo(4)}
                    </div>
                    <div className="mainInfoContainer" >
                        <div className="tableHeader">
                            <h2 id='title' style={{ margin: "auto" }}>{this.state.historyViewTitle}</h2>
                            <input
                                type="text"
                                placeholder={"Search"}
                                width={100}
                                height={50}
                                onChange={this.search}
                                style={{
                                    margin: "auto",
                                    border: "1px solid #e3e1e1",
                                    borderRadius: "6px",
                                }}>
                            </input>
                        </div>
                        <table id='students'>
                            {this.verifyView()}
                        </table>
                    </div>
                </div>
            )
        }

        return (
            <div style={{ height: "100vh" }}>
                <div className="loader"></div>
            </div>
        )
    }
}

export class ItemSelectionU extends React.Component {

    state = {
        user: undefined
    }

    // constructor(props) {
    //     super(props)
    //     console.log(props)
    // }

    render = () => {
        if (this.props.itemRetrieved === "current") {
            return (
                <tr key={this.props.item.email}>
                    <td>{this.props.item.email}</td>
                    <td>{this.props.item.history.location_Taken}</td>
                    <td>{Moment(this.props.item.history.createdAt).format('MMM Do YYYY, HH:mm:ss')}</td>
                    <td>{Moment(this.props.item.history.returnedAt).format('MMM Do YYYY, HH:mm:ss')}</td>
                    <td>{this.props.item.history.amount_charged}</td>
                </tr>
            )
        } else if (this.props.itemRetrieved === "declined") {
            return (
                <tr key={this.props.item.email}>
                    <td>{this.props.item.email}</td>
                    <td>{this.props.item.history.location_Taken}</td>
                    <td>{Moment(this.props.item.history.createdAt).format('MMM Do YYYY, HH:mm:ss')}</td>
                    <td>{Moment(this.props.item.history.returnedAt).format('MMM Do YYYY, HH:mm:ss')}</td>
                    <td>{this.props.item.history.amount_charged}</td>
                </tr>
            )
        } else {
            return (
                <tr key={this.props.item.email}>
                    <td>{this.props.item.email}</td>
                    <td>{this.props.item.history.location_Taken}</td>
                    <td>{Moment(this.props.item.history.createdAt).format('MMM Do YYYY, HH:mm:ss')}</td>
                    <td>{this.props.item.history.location_returned}</td>
                    <td>{Moment(this.props.item.history.returnedAt).format('MMM Do YYYY, HH:mm:ss')}</td>
                    <td>{this.props.item.history.amount_charged}</td>
                </tr>
            )
        }

    }
}