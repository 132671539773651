import React, { Component } from 'react';
import ItemSelection from '../itemSelection';
import Creatediscounts from './createDiscounts';
import SelectedDiscount from './selectedDiscount';

export default class DiscountView extends Component {
    state = {
        graphData: undefined,
        statType: "monthly",
        discounts: [],
        code: "",
        credits: 0,
        note: "",
        discountCondition: "Unlimited Use",
        showTable: true,
        viewButtonText: "CREATE",
        selectedDiscount: undefined
    }

    returnView = () => {
        if (this.state.showTable) {
            return (<table id='students'>
                <tbody>
                    <tr>
                        <th key={Math.random()} onClick={() => { this.props.sort("discounts", "from", 1) }}>From</th>
                        <th key={Math.random()} onClick={() => { this.props.sort("discounts", "code", 1) }}>Code</th>
                        <th key={Math.random()} onClick={() => { this.props.sort("discounts", "type", 1) }}>Type</th>
                        <th key={Math.random()} onClick={() => { this.props.sort("discounts", "redeems", 1) }}>Number of Use</th>
                        <th key={Math.random()} onClick={() => { this.props.sort("discounts", "credits", 1) }}>Promo</th>
                    </tr>
                    {
                        this.props.discountList.map((item, index) => (
                            <ItemSelection key={index}  itemRetrived={"discounts"} item={item} options={this.props.options} selectedItem={(item)=> {this.setState({ selectedDiscount: item}, ()=>{this.changeView()}) }} />
                        ))
                    }
                </tbody>
            </table>
            )
        } else if(this.state.selectedDiscount !== undefined && !this.state.showTable){
            return <SelectedDiscount discount={this.state.selectedDiscount} options={this.props.options}/>
        }else {
            return <Creatediscounts updateList={() => this.props.updateList()} options={this.props.options}/>
        }
    }

    changeView = () => {
        if(this.state.showTable){
            this.setState({ showTable: false, viewButtonText: "LIST" })
        }else{
            this.setState({ showTable: true, viewButtonText: "CREATE", selectedDiscount: undefined })
        }
    }

    render() {
        return (
            <div className="mainInfoContainer" style={{ height: "82vh", marginTop: "5%" }}>
                <div className="tableHeader">
                    <h2 id='title' style={{ margin: "auto" }}>Discounts</h2>
                    <div className="csvExportButton" onClick={this.changeView}>
                        <img src="/img/Icons/export_content.png" alt={"User Icon"} />
                        <p>{this.state.viewButtonText}</p>
                    </div>
                    <input
                        type="text"
                        placeholder={"Search"}
                        width={100}
                        height={50}
                        onChange={this.props.search}
                        style={{
                            margin: "auto",
                            border: "1px solid #e3e1e1",
                            borderRadius: "6px",
                        }}>
                    </input>
                </div>
                {this.returnView()}
            </div>
        )
    }
}