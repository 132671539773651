import React, { Component } from 'react';
import TimePicker from 'react-time-picker';

export default class TimePickerComp extends Component {
  
  onChange = (value) => {
    var state = "open"

    if(!this.props.setOpen){
      state = "close"
    }

    this.props.setStateFunction(state, value, this.props.index)
  }

  render() {
    return (
      <div>
        <div className="timePicker__container">
          <main className="timePicker__container__content">
            <TimePicker
              amPmAriaLabel="Select AM/PM"
              clearAriaLabel="Clear value"
              clockAriaLabel="Toggle clock"
              hourAriaLabel="Hour"
              maxDetail="second"
              minuteAriaLabel="Minute"
              nativeInputAriaLabel="Time"
              onChange={this.onChange}
              secondAriaLabel="Second"
              value={this.props.value}
            />
          </main>
        </div>
      </div>
    );
  }
}
