import React, { Component } from 'react';
import FileUpload from '../../utils/FileUpload';

import TimePickerComp from '../../utils/timePickerComp';
import VenueOwner from './venueOwner';
import Select from 'react-select'

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

export default class CabinetsInfo extends Component {
    // let { username } = useParams();
    link = "about/"
    state = {
        port: "",
        loading: true,
        selectedUserLink: undefined,
        users: undefined,
        venueOwner: undefined,
        image: null,
        address: "",
        location_name: "",
        slot: null,
        search: "",
        cabinet: undefined,
        daily: true,
        dailyArray: {
            open: "06:00:00",
            close: "00:00:00"
        },
        theme: {
            logo: "N/A",
            secondaryColor: "N/A",
            mainColor: "N/A"
        },
        weekly: [
            {
                open: "06:00:00",
                close: "00:00:00"
            },
            {
                open: "06:00:00",
                close: "00:00:00"
            },
            {
                open: "06:00:00",
                close: "00:00:00"
            },
            {
                open: "06:00:00",
                close: "00:00:00"
            },
            {
                open: "06:00:00",
                close: "00:00:00"
            },
            {
                open: "06:00:00",
                close: "00:00:00"
            },
            {
                open: "06:00:00",
                close: "00:00:00"
            }
        ]
    }

    constructor(props) {
        super(props)
        this.getUsers()
        this.getCabinet()
    }

    getUsers = async () => {
        await fetch('https://plugheur.fr/api/v4/registers', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token,
                'Access-Control-Allow-Credentials': true
            },
            credentials: 'omit'
        }).then((res) => res.json())
            .then((data) => {
                var usersopt = []
                if (data.success) {
                    for (var user of data.data) {
                        usersopt.push({
                            value: user.email,
                            label: user.email,
                            userID: user._id
                        })
                    }
                    console.log(usersopt)
                    this.setState({ users: usersopt })
                } else {
                    this.props.getToken(undefined)
                    alert("Not admin")
                }
            })
    }

    getCabinet = async () => {
        await fetch('https://plugheur.fr/api/v4/stations/' + this.props.item, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    this.setState({ cabinet: data.data.cabinet, daily: data.data.cabinet.openDaily, venueOwner: data.data.cabinet.clientInfo? data.data.cabinet.clientInfo.clientId : null }, () => {
                        if (data.data.cabinet.openDaily) {
                            this.setState({ dailyArray: data.data.cabinet.dailyInfo })
                        } else {
                            this.setState({ weekly: data.data.cabinet.weeklyInfo })
                        }
                        this.setState({ loading: false })
                    })
                } else {
                    alert("Failed")
                }
            })
    }

    popBank = async () => {
        var body = {
            cabinetID: this.props.item,
            i: "eye",
            user: { role: "admin" }
        }

        if (this.state.slot !== null) {
            body["slot"] = this.state.slot.toString()
        }

        await fetch('https://plugheur.fr/api/v4/stations/popup', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify(body),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data === "Done") {
                    alert("Success")
                } else {
                    alert("Failed")
                }
            })
    }

    updateAddress = async (address) => {
        if (address === true) {
            var body = {
                openDaily: this.state.daily,
                dailyInfo: this.state.dailyArray
            }

            if (!this.state.daily) {
                body = {
                    openDaily: this.state.daily,
                    weeklyInfo: this.state.weekly
                }
            }

            await fetch('https://plugheur.fr/api/v4/stations/' + this.props.item, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.options.token
                },
                body: JSON.stringify(body),
                credentials: "omit"
            }).then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        alert("Resturaunt Info updated")
                    } else {
                        alert("Resturaunt Info not updated")
                    }
                })
        } else {
            var updateBody = {}

            if (this.state.location_name !== this.state.cabinet.location_name && this.state.location_name !== "") { updateBody["location_name"] = this.state.location_name }
            if (this.state.address !== this.state.cabinet.address && this.state.address !== "") { updateBody["address"] = this.state.address }
            if (this.state.port !== this.state.cabinet.port && this.state.port !== "") { updateBody["port"] = this.state.port }
            if (this.state.theme.logo !== "N/A" || this.state.theme.mainColor !== "N/A" || this.state.theme.secondaryColor !== "N/A") { updateBody["theme"] = this.state.theme }


            // console.log(updateBody)

            await fetch('https://plugheur.fr/api/v4/stations/' + this.props.item, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.options.token
                },
                body: JSON.stringify(updateBody),
                credentials: "omit"
            }).then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        alert("Address updated")
                    } else {
                        alert("Address not updated")
                    }
                })
        }
    }

    updateInputValue = (evt) => {
        this.setState({
            address: evt.target.value
        });
    }

    updateInputValue2 = (evt) => {
        this.setState({
            location_name: evt.target.value
        });
    }

    updateInputValue3 = (evt) => {
        this.setState({
            slot: evt.target.value
        });
    }

    setTimeState = (state, value, index) => {
        var obj = this.state.dailyArray

        if (this.state.daily) {
            obj[state] = value
            this.setState({ dailyArray: obj })
        } else {
            var fulllist = this.state.weekly
            fulllist[index][state] = value
            this.setState({ weekly: fulllist })
        }
    }

    returnOpenCloseSection = (item, index) => {
        var day = "Monday"

        if (index === undefined) {
            day = null
        } else if (index === 1) {
            day = "Tuesday"
        } else if (index === 2) {
            day = "Wednesday"
        } else if (index === 3) {
            day = "Thursday"
        } else if (index === 4) {
            day = "Friday"
        } else if (index === 5) {
            day = "Saturday"
        } else if (index === 6) {
            day = "Sunday"
        }

        return (
            <div className="timePickerContainer">
                <div>{day}</div>
                <div className="timePicker">
                    <span>Open</span>
                    <TimePickerComp setOpen={true} index={index} value={item.open} setStateFunction={this.setTimeState} />
                </div>
                <div className="timePicker">
                    <span>Close</span>
                    <TimePickerComp setOpen={false} index={index} value={item.close} setStateFunction={this.setTimeState} />
                </div>
            </div>
        )
    }

    returnTimePicker = () => {
        if (this.state.daily) {
            return this.returnOpenCloseSection(this.state.dailyArray, undefined)
        }

        return this.state.weekly.map((item, index) => {
            return this.returnOpenCloseSection(item, index)
        })
    }

    selectorOnChange = (event) => {
        var daily = true
        if (event.target.value !== "Daily") {
            daily = false
        }
        this.setState({ daily })
    }

    selectorOnChangeV = (event) => {
        this.setState({ venueOwner: event.target.value })
    }

    newImage = async (path) => {
        var pics = this.state.cabinet.venueDisplayPic
        var cabinet = this.state.cabinet
        var newPic = []
        var done = false

        if (!path.delete) {
            for (var n in pics) {
                if (pics[n] === "" && !done) { newPic.push(path); done = true }
                else { newPic.push(pics[n]) }
            }
        } else {

            for (var x in pics) {
                if (x === path.i.toString()) { newPic.push(""); this.deletePic(pics[x]); }
                else { newPic.push(pics[x]) }
            }
        }

        cabinet.venueDisplayPic = ["", "", "", "", ""];
        this.setState({
            cabinet
        }, () => {
            cabinet.venueDisplayPic = newPic;
            this.setState({
                cabinet
            })
        })

        await fetch('https://plugheur.fr/api/v4/stations/' + this.props.item, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify({
                venueDisplayPic: newPic
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    alert("Resturaunt Info updated")
                    this.getCabinet()
                } else {
                    alert("Resturaunt Info not updated")
                }
            })
    }

    deletePic = async (path) => {
        await fetch(`https://plugheur.fr/api/v4/stations/${this.props.item}/photo`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify({
                path: path
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    alert("Resturaunt Info updated")
                } else {
                    alert("Resturaunt Info not updated")
                }
            })
            .catch((err) => { alert(err) })
    }

    linkUserToCabinet = async () => {
        this.setState({ loading: true })
        await fetch(`https://plugheur.fr/api/v4/check/linkUserToCabinet`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify({
                cabinet: this.state.cabinet,
                user: this.state.selectedUserLink.userID
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                this.setState({ loading: false })
                if (data.success) {
                    this.getCabinet()
                    alert("User Linked")
                } else {
                    alert("User not linked")
                }
            })
            .catch((err) => { alert(err) })
    }

    unlinkUserToCabinet = async () => {
        this.setState({ loading: true })
        await fetch(`https://plugheur.fr/api/v4/check/unlinkUserToCabinet`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify({
                cabinet: this.state.cabinet,
                user: this.state.cabinet.owner
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                this.setState({ loading: false })
                if (data.success) {
                    this.getCabinet()
                    alert("User unLinked")
                } else {
                    alert("User not unlinked")
                }
            })
            .catch((err) => { alert(err) })
    }

    verifyWithUser = (index, result) => {
        if (result) this.newImage({ delete: true, i: index })
    }

    returnVenuImages = () => {
        return (
            <div className="venuImages">
                {this.state.cabinet.venueDisplayPic.map((pic, index) => {
                    return pic === "" || pic === undefined ? null : <img onClick={() => { this.verifyWithUser(index, window.confirm("Are you sure you want to delete the selected pic.")) }} key={index} src={"https://plugheur.fr" + pic} alt="Venue" />
                })}
            </div>
        )
    }

    render() {
        var selector = "Daily"

        if (!this.state.daily) {
            selector = "Weekly"
        }

        if (this.state.cabinet !== undefined && this.state.users !== undefined && this.state.loading === false) {
            return (
                <div className="mainInfoContainer cabinetInfoContainer">
                    <h2>{this.props.item}</h2>
                    <div className="inputContainer">
                        <span>Addres</span>
                        <input
                            type="text"
                            placeholder={this.state.cabinet.address}
                            width={100}
                            height={50}
                            onChange={this.updateInputValue}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Location Name</span>
                        <input
                            type="text"
                            placeholder={this.state.cabinet.location_name}
                            width={100}
                            height={50}
                            onChange={this.updateInputValue2}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Slot</span>
                        <input
                            type="text"
                            placeholder="Slot"
                            width={100}
                            height={50}
                            onChange={this.updateInputValue3}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Port</span>
                        <input
                            type="text"
                            placeholder={this.state.cabinet.port ? this.state.cabinet.port : "Station hasn't connected to show port."}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ port: evt.target.value }) }}>
                        </input>
                    </div>
                    {/* <div className="inputContainer">
                        <span>Number of rentals</span>
                        <input
                            type="text"
                            placeholder={this.state.history.length ? }
                            width={100}
                            height={50}
                            onChange={null}>
                        </input>
                    </div> */}
                    <h3>Theme Settings</h3>
                    <div className="inputContainer">
                        <span>Logo</span>
                        <input
                            type="text"
                            placeholder={this.state.cabinet.theme ? this.state.cabinet.theme.logo : this.state.theme.logo}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ theme: { ...this.state.theme, logo: evt.target.value } }) }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Main Color</span>
                        <input
                            type="text"
                            placeholder={this.state.cabinet.theme ? this.state.cabinet.theme.mainColor : this.state.theme.mainColor}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ theme: { ...this.state.theme, mainColor: evt.target.value } }) }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Secondary Color</span>
                        <input
                            type="text"
                            placeholder={this.state.cabinet.theme ? this.state.cabinet.theme.secondaryColor : this.state.theme.secondaryColor}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ theme: { ...this.state.theme, secondaryColor: evt.target.value } }) }}>
                        </input>
                    </div>

                    <div className="cabinetInfoUpdateButtons">
                        <button onClick={this.updateAddress} className="cabinetInfoEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Update Address</span>
                        </button>
                        <button onClick={this.popBank} className="cabinetInfoEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Pop Power Bank</span>
                        </button>
                    </div>

                    { !this.state.cabinet.owned ?
                        <>
                            <h3>Link User To Cabinet</h3>
                            <div style={{ width: "95%" }}>
                                <Select
                                    onChange={selectedOption => {
                                        this.setState({ selectedUserLink: selectedOption });
                                    }}
                                    options={this.state.users}
                                />
                            </div>
                        </> :
                        <div className="cabinetInfoUpdateButtons">
                            <button onClick={this.unlinkUserToCabinet} className="cabinetInfoEditButton">
                                <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                                <span>UnLink User</span>
                            </button>
                        </div>
                    }
                    {this.state.selectedUserLink != undefined &&
                        <div className="cabinetInfoUpdateButtons">
                            <button onClick={this.linkUserToCabinet} className="cabinetInfoEditButton">
                                <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                                <span>Link User</span>
                            </button>
                        </div>
                    }
                    {/*                     
                    <h2>Venue Information</h2>
                    <h3>Venue Images</h3>
                    <FileUpload id={this.props.item} options={this.props.options} newUploadImage={this.newImage} />
                    {this.returnVenuImages()}
                    <h3>Venue Details/Menues</h3>
                    <div className="cabinetInfoSelectTime">
                        <span>Client Id</span>
                        <select name="cars" id="cars" form="carform" onChange={this.selectorOnChangeV}>
                            {this.state.cabinet.clientInfo.clientId === "" ? <option value={"Select"}>Select</option> : null}
                            {this.state.users.map((user, i) => {
                                return user.role === "venue" ? <option key={i} value={user._id}>{user.name}</option> : null
                            })}
                        </select>
                    </div>

                    {this.state.venueOwner !== undefined && this.state.venueOwner !== "" ?
                        <VenueOwner cabinet={this.state.cabinet} owner={this.state.venueOwner} menu={this.state.cabinet.clientInfo.menus} />
                        : null}
                    <h3>Venue Times</h3>
                    <div style={{ marginBottom: 10 }}>
                        <div className="cabinetInfoSelectTime">
                            <span>Select Time Type:</span>
                            <select id="type" onChange={this.selectorOnChange} value={selector}>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                            </select>
                        </div>
                        {
                            this.returnTimePicker()
                        }
                        <button onClick={() => { this.updateAddress(true) }} className="cabinetInfoEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Update Times</span>
                        </button>
                    </div> */}
                </div>
            )
        } else {
            return (
                <div style={{ height: "100vh" }}>
                    <div className="loader"></div>
                </div>
            )
        }
    }
}