import React from "react";
import {
    Link
} from "react-router-dom";

const MOMENT = require('moment');

export default class Users extends React.PureComponent {

    state = {
        user: undefined,
        userHistory: undefined,
        currentOnGoing: undefined,
        cardAdded: false,
        currentRental: false,
        notificationTitle: undefined,
        notificationMsg: undefined,
        name: "",
        surname: "",
        email: "",
        password: "",
        phone_num: "",
        role: "",
        city: "",
        verified: false
    }

    constructor(props) {
        super(props)
        if (this.props.item) {
            this.getUserHistory()
        }
    }

    getUserHistory = async () => {
        await fetch('https://plugheur.fr/api/v4/check/user_history', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data.succes) {
                    // var payload = data.data
                    var current = []
                    var old = []

                    for (var x in data.data) {
                        if (data.data[x].history.location_returned === "On Going" && data.data[x].history.UserID === this.props.item._id) {
                            current.push(data.data[x])
                        } else if (data.data[x].history.location_returned !== "On Going" && data.data[x].history.UserID === this.props.item._id) {
                            old.push(data.data[x])
                        }
                    }

                    if (this.props.item.stripe_customerId.length > 0 || this.props.item.stripe_customerId !== "") {
                        this.setState({ cardAdded: true })
                    }

                    if (current.length > 0) {
                        this.setState({ currentRental: true })
                    }

                    this.setState({ userHistory: old, currentOnGoing: current })
                } else {
                    alert("39" + data.error)
                }

            })
    }

    TimeDiff = (startTime, endTime, format) => {
        startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
        endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
        return endTime.diff(startTime, format, true);
    }

    getLastSeen = () => {
        var last_seen = this.TimeDiff(new Date(this.props.item.last_seen), new Date(), 'days')
        if (last_seen >= 1) {
            return (
                parseInt(last_seen) + " day(s) ago"
            )
        } else {
            return (
                "Today"
            )
        }
    }

    sendUserPush = async () => {
        var to = this.props.item._id;

        if (this.state.notificationTitle === undefined || this.state.notificationMsg === undefined) {
            alert("Title and Message required to send notifications.")
            return;
        }

        await fetch('https://plugheur.fr/api/v4/check/sendUserPush', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify({
                notification: {
                    'title': this.state.notificationTitle,
                    'body': this.state.notificationMsg,
                },
                from: to
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (!data.success) (alert(data.error))
                else { alert("Message Sent.") }
            })
    }

    notificationBlock = () => {
        return (
            <div style={{ marginBottom: 10 }}>
                <div className="inputContainer">
                    <span>Title</span>
                    <input
                        type="text"
                        placeholder={"Notification Title"}
                        width={100}
                        height={50}
                        onChange={(evt) => { this.setState({ notificationTitle: evt.target.value }); }}>
                    </input>
                </div>
                <div className="inputContainer">
                    <span>Message</span>
                    <input
                        type="text"
                        placeholder={"Notification Message"}
                        width="auto"
                        height={50}
                        onChange={(evt) => { this.setState({ notificationMsg: evt.target.value }); }}>
                    </input>
                </div>
                <button onClick={() => this.sendUserPush()} className="userEditButton">
                    <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                    <span>Send</span>
                </button>
            </div>
        )
    }

    updateUser = async () => {
        var details = {};
        if (this.state.phone_num.toString().trim().length > 0) {
            details.phone_num = this.state.phone_num.toString().trim()
        }
        if (this.state.name.toString().trim().length > 0) {
            details.name = this.state.name.toString().trim()
        }
        if (this.state.surname.toString().trim().length > 0) {
            details.surname = this.state.surname.toString().trim()
        }
        if (this.state.email.toString().trim().length > 0) {
            details.email = this.state.email.toString().trim()
        }
        if (this.state.verified !== this.props.item.verified) {
            details.verified = this.state.verified
        }
        if (this.state.city.toString().trim().length > 0) {
            details.city = this.state.city.toString().trim()
        }
        if (this.state.credits !== this.props.item.credits && this.state.credits !== undefined && this.state.credits > 0) {
            details.credits = parseFloat(this.state.credits).toFixed(2)
        }

        // console.log(details)
        await fetch("https://plugheur.fr/api/v4/registers/" + this.props.item._id, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify(
                details
            ),
            credentials: 'omit'
        }).then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    alert("User Account Updated.")
                } else {
                    alert("Account Not Updated, verify details.")
                }
            })
            .catch((err) => console.log(err))
    }

    deleteUser = async () => {
        await fetch("https://plugheur.fr/api/v4/registers/" + this.props.item._id, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            credentials: 'omit'
        }).then((res) => res.json())
            .then((data) => {
                if (!data.success) (alert("Error deleting user. Try again."))
                else { alert("User deleted.") }
            })
            .catch((err) => console.log(err))
    }

    render() {
        if (this.props.item && this.state.currentOnGoing !== undefined && this.state.userHistory !== undefined) {
            return (
                <div className="mainInfoContainer userContainer" >
                    <h2>User Profile</h2>
                    <div className="inputContainer">
                        <span>Name</span>
                        <input
                            type="text"
                            placeholder={this.props.item.name}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ name: evt.target.value }); }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Surname</span>
                        <input
                            type="text"
                            placeholder={this.props.item.surname === "" || this.props.item.surname === undefined ? "Surname Not Added Yet" : this.props.item.surname}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ surname: evt.target.value }); }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>City</span>
                        <input
                            type="text"
                            placeholder={this.props.item.city === undefined || this.props.item.city === "" ? "No City Added" : this.props.item.city}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ city: evt.target.value }); }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Email</span>
                        <input
                            type="text"
                            placeholder={this.props.item.email}
                            width="auto"
                            height={50}
                            onChange={(evt) => { this.setState({ email: evt.target.value }); }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Phone No</span>
                        <input
                            type="text"
                            placeholder={this.props.item.phone_num}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ phone_num: evt.target.value }); }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>credits</span>
                        <input
                            type="number"
                            placeholder={this.props.item.credits}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ credits: evt.target.value }); }}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>DOB</span>
                        <input
                            type="text"
                            placeholder={this.props.item.DOB === "" || this.props.item.DOB === undefined ? "Date Of Birth Not Added Yet" : this.props.item.DOB}
                            width={100}
                            height={50}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Card Added</span>
                        <input
                            type="text"
                            placeholder={this.props.item.stripe_customerId === "" ? "No" : "Yes"}
                            width={100}
                            height={50}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Account %</span>
                        <input
                            type="text"
                            placeholder={this.props.item.accountLevel === undefined || this.props.item.accountLevel === 0 ? "Account to be updated for changes to reflect" : this.props.item.accountLevel.toString() + "%"}
                            width={100}
                            height={50}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Date Registered</span>
                        <input
                            type="text"
                            placeholder={new Date(this.props.item.createdAt).toLocaleDateString() + " " + new Date(this.props.item.createdAt).toLocaleTimeString()}
                            width={100}
                            height={50}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span style={{ fontWeight: "bold", fontSize: 14 }}><Link to={"/history/" + this.props.item._id}>Number Of Rentals</Link></span>
                        <input
                            type="text"
                            placeholder={this.state.userHistory.length}
                            width={100}
                            height={50}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Current Rental</span>
                        <input
                            type="text"
                            placeholder={this.state.currentRental.toString()}
                            width={100}
                            height={50}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Last Online</span>
                        <input
                            type="text"
                            placeholder={this.getLastSeen()}
                            width={100}
                            height={50}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="inputContainer">
                        <span>Refferal Code</span>
                        <input
                            type="text"
                            placeholder={this.props.item.referralCode}
                            width={100}
                            height={50}
                            onChange={null}
                            readOnly={true}>
                        </input>
                    </div>
                    <div className="cabinetInfoSelectTime">
                        <span>Verified</span>
                        <select name="cars" id="cars" form="carform" onChange={(event) => { this.setState({ verified: event.target.value }) }}>
                            <option value={this.props.item.verified.toString()}>{this.props.item.verified.toString()}</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>

                    <div className="cabinetInfoUpdateButtons">
                        <button onClick={() => { this.updateUser() }} className="userEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Update</span>
                        </button>
                        <button onClick={() => {if(window.confirm("Are you sure you want to delete the selected user?")){this.deleteUser()}}} className="userEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Delete</span>
                        </button>
                    </div>
                    <h4>Send User a Push</h4>
                    {this.props.item.fire_token !== "" ? this.notificationBlock() : <div>User hasn't upgraded to register their device for personal notification.</div>}
                </div>
            )
        } else {
            if (!this.props.item) {
                return (
                    <div>
                        Not Authorized
                    </div>
                )
            } else {
                return (
                    <div style={{ height: "100vh" }}>
                        <div className="loader"></div>
                    </div>
                )
            }
        }
    }
}