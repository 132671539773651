import React, { Component } from 'react';

export default class SelectedDiscount extends Component {
    state = {
        showTable: false,
        graphData: undefined,
        statType: "monthly",
        maxUsers: 0,
        discounts: [],
        code: "",
        credits: 0,
        note: "",
        discountCondition: "Unlimited Use"
    }

    change = (evt) => {
        this.setState({
            discountCondition: evt.target.value
        });
    }

    updateInputValue4 = (evt) => {
        this.setState({
            code: evt.target.value
        });
    }

    updateInputValue5 = (evt) => {
        this.setState({
            credits: evt.target.value
        });
    }

    updateInputValue6 = (evt) => {
        this.setState({
            note: evt.target.value
        });
    }

    createDiscount = async () => {
        if (this.state.code !== "" && this.state.credits !== "") {
            await fetch("https://plugheur.fr/api/v4/check/discounts", {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.options.token
                },
                body: JSON.stringify({
                    code: this.state.code,
                    credits: parseFloat(this.state.credits),
                    discountCondition: this.state.discountCondition,
                    note: this.state.note.toString(),
                    maxUsers: this.state.maxUsers
                }),
                credentials: "omit"
            }).then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        alert("Discount created")
                        this.props.updateList()
                    } else {
                        alert("Discount creation failed.")
                    }
                })
        } else {
            alert("Please fill in code and credits")
        }
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <h3 style={{ marginLeft: 60, marginTop: 60 }}>Promo Code</h3>
                <div style={{ padding: "10px 60px" }}>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>FROM</span>
                        <input
                            type="text"
                            placeholder={"FROM"}
                            width={100}
                            height={50}
                            onChange={() => { }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>TO</span>
                        <input
                            type="text"
                            placeholder={"TO"}
                            width={100}
                            height={50}
                            onChange={() => { }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Type</span>
                        <input
                            type="text"
                            placeholder={"TO"}
                            width={100}
                            height={50}
                            onChange={() => { }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Code</span>
                        <input
                            type="text"
                            placeholder={this.props.discount.code}
                            width={100}
                            height={50}
                            onChange={this.updateInputValue4}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Credits/Promo</span>
                        <input
                            type="text"
                            placeholder={this.props.discount.credits}
                            width={100}
                            height={50}
                            onChange={this.updateInputValue5}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>maxUsers</span>
                        <input
                            type="text"
                            placeholder={this.props.discount.maxUsers ? this.props.discount.maxUsers: "No Max Users defined"}
                            width={100}
                            height={50}
                            onChange={(evt) => { this.setState({ maxUsers: evt.target.value }) }}>
                        </input>
                    </div>
                    <div style={{ width: "100%" }} className="inputContainer">
                        <span>Note</span>
                        <input
                            type="text"
                            placeholder={this.props.discount.note ? this.props.discount.note: "No note/message defined"}
                            width={100}
                            height={50}
                            onChange={this.updateInputValue6}>
                        </input>
                    </div>
                </div>
                <div className="cabinetInfoUpdateButtons">
                    <button onClick={null} className="cabinetInfoEditButton">
                        <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                        <span>Update Discount</span>
                    </button>
                    <button onClick={null} className="cabinetInfoEditButton">
                        <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                        <span>Delete Discount</span>
                    </button>
                </div>
            </div>
        )
    }
}
