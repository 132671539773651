import React from "react";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
const MOMENT = require('moment');

export default class CabinetsMap extends React.PureComponent {

    state = {
        viewport: {
            latitude: 44.857405,
            longitude: -0.560383,
            width: "100vw",
            height: "92vh",
            zoom: 13
        },
        selectedCabinet: null,
        address: "",
        location_name: ""
    }

    // constructor(props) {
    //     super(props)
    //     // console.log(props.Cabinets[0].location.coordinates[1])

    // }

    //   useEffect(() => {
    //     const listener = e => {
    //       if (e.key === "Escape") {
    //         setSelectedCabinet(null);
    //       }
    //     };
    //     window.addEventListener("keydown", listener);

    //     return () => {
    //       window.removeEventListener("keydown", listener);
    //     };
    //   }, []);

    popBank = async () => {
        await fetch('https://plugheur.fr/api/v4/stations/popup', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify({
                cabinetID: this.state.selectedCabinet.name
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                // console.log("Cabinets", data)
                if (data === "Done") {

                    // this.setState({ cabinets: data.data })
                    alert("Success")

                } else {
                    // this.props.getToken(undefined)
                    alert("Failed")
                }
            })
    }

    returnMarker = (cabinet) => {
        var cabinetName = "cabinet_empty.png"

        const TimeDiff = (startTime, endTime, format) => {

            startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
            endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
            return endTime.diff(startTime, format, true);
        }

        if (parseInt(TimeDiff(new Date(cabinet.cabinet.lastSeen), new Date(), 'minutes')) > 1) {
            cabinetName = "cabinet_selected.png"
        }

        return (
            <Marker
                key={cabinet.name}
                latitude={cabinet.cabinet.location.coordinates[1]}
                longitude={cabinet.cabinet.location.coordinates[0]}
            >
                <img onClick={e => {
                    e.preventDefault();
                    this.setState({ selectedCabinet: cabinet })
                }} src={"/img/" + cabinetName} width={40} height={40} alt="Skate Park Icon" />
            </Marker>
        )
    }

    updateAddress = async () => {
        // console.log(this.state)
        var update = undefined
        if (this.state.address !== "") {
            if (this.state.location_name !== "") {
                update = {
                    location_name: this.state.location_name,
                    address: this.state.address
                }
            } else {
                alert("Please fill in address and location name.")
            }

        } else if (this.state.location_name !== "") {
            update = {
                location_name: this.state.location_name,
            }
        } else {
            alert("Please fill in details above")
        }

        await fetch('https://plugheur.fr/api/v4/stations/' + this.state.selectedCabinet.cabinet.name, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify(update),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    alert("Address updated")
                } else {
                    alert("Address not updated")
                }
            })
    }

    updateInputValue = (evt) => {
        this.setState({
            address: evt.target.value
        });
    }

    updateInputValue2 = (evt) => {
        this.setState({
            location_name: evt.target.value
        });
    }

    render() {
        return (
            <div>
                <ReactMapGL
                    {...this.state.viewport}
                    mapboxApiAccessToken={"pk.eyJ1IjoicGx1Z2hldXIiLCJhIjoiY2sycnV1aTEwMGJ3eTNudGpwcGhmdDhsciJ9.oWcOIc7dnBZMxexUGXmxNw"}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    onViewportChange={viewport => {
                        this.setState({ viewport });
                    }}
                >
                    {this.props.Cabinets.map(cabinet => {
                        return (
                            this.returnMarker(cabinet)
                        )
                    })
                    }

                    {this.state.selectedCabinet ? (
                        <Popup
                            latitude={this.state.selectedCabinet.cabinet.location.coordinates[1]}
                            longitude={this.state.selectedCabinet.cabinet.location.coordinates[0]}
                            closeOnClick={false}
                            onClose={() => {
                                this.setState({ selectedCabinet: null })
                            }}
                        >
                            <div>
                                <h2>{this.state.selectedCabinet.cabinet.name}</h2>
                                <div style={{ padding: 50 }}>
                                    <input
                                        type="text"
                                        placeholder={this.state.selectedCabinet.cabinet.address}
                                        width={100}
                                        height={50}
                                        onChange={this.updateInputValue}>
                                    </input>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={this.state.selectedCabinet.cabinet.location_name}
                                            width={100}
                                            height={50}
                                            onChange={this.updateInputValue2}>
                                        </input>
                                    </div>
                                    <div>
                                        Total Rentals: { this.state.selectedCabinet.history.length }
                                    </div>
                                    <button onClick={this.updateAddress}>Update Address</button>
                                    <button onClick={this.popBank}>Pop Bank</button>
                                </div>
                            </div>
                        </Popup>
                    ) : null}
                </ReactMapGL>
            </div>
        );
    }
}
