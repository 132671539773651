import React, { Component } from 'react';

export default class Login extends Component {

    state = {
        email: "",
        password: ""
    }

    login = (e) => {
        e.preventDefault();
        fetch('https://plugheur.fr/api/v4/check/login', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                // console.log(data);

                if (data.success) {
                    this.props.getToken(data.token)
                }
            })
            .catch((err) => console.log(err))
    }

    updateInputValue = (evt) => {
        this.setState({
            email: evt.target.value
        });
    }

    updateInputValue2 = (evt) => {
        this.setState({
            password: evt.target.value
        });
    }

    render = () => {
        return (
            <div align="center" className="container_login">
                <img className="login_header_img" src="/img/Login/plugheur_header.png" alt={"Plug'Heur"} />

                <div className="container_login_content">
                    <div className="container_login_content_form">
                        <h2>Login</h2>

                        <div className="login_input">
                            <img className="login_input_icon" src="/img/Login/Email_Icon.png" alt={"Email Icon"} />
                            <input
                                type="text"
                                placeholder={"Email address"}
                                onChange={this.updateInputValue}>
                            </input>
                        </div>

                        <div className="login_input">
                            <img className="login_input_icon" src="/img/Login/Password_Icon.png" alt={"Password Icon"} />
                            <input
                                type="password"
                                placeholder={"Password"}
                                onChange={this.updateInputValue2}>
                            </input>
                        </div>

                        <button className="login_input_button" type="submit" onClick={this.login}>Login</button>
                    </div>
                    <img className="login_splash_img" src="/img/Login/plugheur_login_splash.png" alt={"Plug'Heur Splash"} />
                </div>
            </div>
        )
    }
}