import React, { Fragment, Component } from 'react';
// import Message from './Message';
// import Progress from './Progress';
import axios from 'axios';

export default class FileUpload extends Component {
    state = {
        filename: 'Choose File'
    }

    onChange = e => {
        this.onSubmit(e.target.files[0]);
    };

    onSubmit = async e => {
        const formData = new FormData();
        formData.append('file', e);

        try {
            const res = await axios.put(`https://plugheur.fr/api/v4/stations/${this.props.id}/photo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.props.options.token
                },
                onUploadProgress: progressEvent => {
                    // setUploadPercentage(
                    //     parseInt(
                    //         Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    //     )
                    // );

                    // // Clear percentage
                    // setTimeout(() => setUploadPercentage(0), 10000);
                }
            });

            const { fileName, filePath } = res.data;
            this.setState({ fileName })
            this.props.newUploadImage(filePath)
        } catch (err) {
            if (err.response.status === 500) {
                // setMessage('There was a problem with the server');
            } else {
                // setMessage(err.response.data.msg);
            }
        }
    }

    render() {
        return (
            <Fragment>
                <div style={{ display: "flex" }}>
                    <input type="file" id="file" onChange={this.onChange} />
                    <label htmlFor="file">{this.state.filename}</label>
                </div>
            </Fragment>
        );
    }
};
