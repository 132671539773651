import React, { Component } from 'react';
import ItemSelection from '../itemSelection';

const MOMENT = require('moment');

export default class CabinetsView extends Component {
    state = {
        showTable: false,
        graphData: undefined,
        statType: "monthly",
    }

    componentDidMount = () => {
        // this.exportCSV()
    }

    TimeDiff = (startTime, endTime, format) => {
        startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
        endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
        return endTime.diff(startTime, format, true);
    }

    verifyCabinetOnlineStatus = (date) => {
        if (parseInt(this.TimeDiff(new Date(date), new Date(), 'minutes')) < 1) {
            return "Online"
        }

        return "Offline"
    }

    showLoader = () => {
        return (<div class="loader"></div>)
    }

    TimeDiff = (startTime, endTime, format) => {
        startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
        endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
        return endTime.diff(startTime, format, true);
    }

    returnTable = () => {
        return (
            <div className="mainInfoContainer" >
                <div className="tableHeader">
                    <h2 id='title' style={{ margin: "auto" }}>Stations</h2>
                    <div align="center" style={{ margin: "auto" }}>
                        <span style={{ marginRight: 20, cursor: "pointer" }} onClick={() => { this.props.setCabinetsViewType("map") }}>Map</span>
                        <span style={{ marginRight: 20, cursor: "pointer" }} onClick={() => { this.props.setCabinetsViewType("list") }}>List</span>
                    </div>
                    <a href="./stations.csv">
                        <div className="csvExportButton">
                            <img src="/img/Icons/export_content.png" alt={"User Icon"} />
                            <p>EXPORT</p>
                        </div>
                    </a>
                    <input
                        type="text"
                        placeholder={"Search"}
                        width={100}
                        height={50}
                        onChange={this.props.search}
                        style={{
                            margin: "auto",
                            border: "1px solid #e3e1e1",
                            borderRadius: "6px",
                        }}>
                    </input>
                </div>
                <table id='students'>
                    <tbody>
                        <tr>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "name", 1) }}>Station Name</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "location_name", 1) }}>Location Name</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "date", 1) }}>Instalation Date</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "rentals", 1) }}>Number of Rentals</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "city", 1) }}>City</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "online", 1) }}>Online</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "powerBanks", 1) }}>Powerbanks</th>
                            <th key={Math.random()} onClick={() => { this.props.sort("cabinetsView", "dateSeen", 1) }}>Last Seen</th>
                        </tr>
                        {
                            this.props.users.map((item, index) => (
                                <ItemSelection key={index} itemRetrived={"Stations"} item={item} options={this.props.options} selectedItem={this.selectedCabinet} />
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    selectedCabinet = (userId) => {
        this.props.selectedItem(userId)
    }

    exportCSV = async () => {
        var msg = "StationName,LocationName,InstalationDate,NumberofRentals,OnlineStatus,Powerbanks\n"

        var users = this.props.users

        for (var u in users) {
            msg += users[u].cabinet.name + "," + users[u].cabinet.location_name + "," + new Date(users[u].cabinet.createdAt).toLocaleDateString() + "," + users[u].history.length + "," + this.verifyCabinetOnlineStatus(users[u].cabinet.lastSeen) + "," + users[u].cabinet.powerBanks + "\n"
        }

        await fetch('https://plugheur.fr/api/v4/check/createUsersCsv', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            body: JSON.stringify({
                message: msg,
                route: "stations"
            }),
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
            })
    }

    render() {
        return (
            this.returnTable()
        )
    }
}