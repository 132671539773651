import React, { Component } from 'react';

export default class VenueOwner extends Component {

    state = {
        owner: undefined,
        menus: []
    }

    constructor(props) {
        super(props)
        this.setState({
            owner: this.props.owner,
            menus: this.props.menus
        })
    }

    addMenu = () => {
        var menuCpy = this.state.menus
        if (menuCpy.length > 4) { alert("Can only add 5 menues.") }
        else {
            menuCpy.push({
                menuID: Math.random(),
                type: "Add Type",
                menuItems: []
            })
        }

        this.setState({ menus: menuCpy })
    }

    addMenuItem = (menu) => {
        var menuCpy = menu.menuItems
        var oldMenuArray = this.state.menus
        var newMenuArray = []
        if (menuCpy.length > 4) { alert("Can only add 5 menues items.") }
        else {
            menuCpy.push({
                name: "Menu Item Name",
                price: "€ 0.00"
            })
        }

        for (var menus in oldMenuArray) {
            if (oldMenuArray[menus].menuID === menu.menuID) {
                oldMenuArray[menus].menuItems = menuCpy
                newMenuArray.push(oldMenuArray[menus])
            } else {
                newMenuArray.push(oldMenuArray[menus])
            }
        }
        // console.log(menuCpy)
        this.setState({ menus: newMenuArray })
    }

    render() {
        return (
            <div style={{ marginTop: 15 }}>
                {this.state.menus.map((menu, key) => {
                    return (
                        <div>
                            <div key={menu.menuID} className="inputContainer">
                                <span>Menu Type</span>
                                <input
                                    type="text"
                                    placeholder={menu.type}
                                    width={100}
                                    height={50}
                                    onChange={null}>
                                </input>
                            </div>
                            {menu.menuItems.map((menuItem, key) => {
                                return (
                                    <div className="menuItemContainer">
                                        {/* <h5>Menu Items</h5> */}
                                        <div key={key} className="inputContainer">
                                            <span>Name</span>
                                            <input
                                                type="text"
                                                placeholder={menuItem.name}
                                                width={100}
                                                height={50}
                                                onChange={null}>
                                            </input>
                                        </div>
                                        <div key={key} className="inputContainer">
                                            <span>Price</span>
                                            <input
                                                type="text"
                                                placeholder={menuItem.price}
                                                width={100}
                                                height={50}
                                                onChange={null}>
                                            </input>
                                        </div>
                                    </div>
                                )
                            })}
                            {menu.menuItems.length >= 5 ? null :
                                <button style={{ marginLeft: "65.5%" }} onClick={() => this.addMenuItem(menu)} className="cabinetInfoEditButton">
                                    <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                                    <span>Add Menu Item</span>
                                </button>
                            }
                        </div>
                    )
                })}
                <div className="cabinetInfoUpdateButtons">
                    {this.state.menus.length >= 5 ? null :
                        <button onClick={this.addMenu} className="cabinetInfoEditButton">
                            <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                            <span>Add Menu</span>
                        </button>
                    }
                    <button onClick={null} className="cabinetInfoEditButton">
                        <img src="/img/Icons/account_edit.png" alt={"User Icon"} />
                        <span>Update Venue</span>
                    </button>
                </div>
            </div>
        )
    }

}