import React, { Component } from 'react';

export default class ResetToken extends Component {
    state = {
      id: this.props.id,
      CurrentPassword: undefined,
      newPassword: undefined
    }
  
    updateInputValue = (evt) => {
      this.setState({
        CurrentPassword: evt.target.value
      });
    }
  
    updateInputValue2 = (evt) => {
      this.setState({
        newPassword: evt.target.value
      });
    }
  
    resetPassword = async () => {
      if (this.state.newPassword !== undefined) {
        await fetch('https://plugheur.fr/api/v4/check/resetpassword/' + this.state.id, {
          method: 'PUT',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            password: this.state.newPassword
          }),
          credentials: "omit"
        }).then((res) => res.json())
          .then((data) => {
            if (data.success) {
              // this.setState({ users: data.data })
              alert("Password update successful.")
              window.location.replace("https://plugheur.app/")
            } else {
              alert("Invalid details entered !")
            }
          })
      } else {
        alert("Please enter details above.")
      }
    }
  
    render() {
      return (
        <div className="container" >
          <span style={{ marginTop: 20 }}></span>
  
          <label><b>Nouveau Mot de passe</b></label>
          <input
            type="password"
            placeholder={"Nouveau Mot de passe"}
            width={100}
            height={50}
            onChange={this.updateInputValue2}>
          </input>
  
          <button type="submit" onClick={this.resetPassword}>Modifier le mot de passe</button>
        </div>
      )
    }
  }