import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
const MOMENT = require('moment');

export default class ItemSelection extends Component {

  link = "about/"
  state = {
    address: "",
    location_name: "",
    slot: null,
    search: ""
  }

  TimeDiff = (startTime, endTime, format) => {
    startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
    endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
    return endTime.diff(startTime, format, true);
  }

  verifyCabinetOnlineStatus = (date) => {
    if (parseInt(this.TimeDiff(new Date(date), new Date(), 'minutes')) < 1) {
      return "Online"
    }

    return "Offline"
  }

  popBank = async () => {
    if (this.state.slot !== null) {
      await fetch('https://plugheur.fr/api/v4/stations/popup', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + this.props.options.token
        },
        body: JSON.stringify({
          cabinetID: this.props.item.cabinet.name,
          i: "eye",
          slot: this.state.slot.toString()
        }),
        credentials: "omit"
      }).then((res) => res.json())
        .then((data) => {
          if (data === "Done") {
            alert("Success")
          } else {
            alert("Failed")
          }
        })
    } else {
      alert("Failed, slot is required.")
    }
  }

  updateAddress = async () => {
    // console.log(this.state)
    if (this.state.address !== "" && this.state.location_name !== "") {
      await fetch('https://plugheur.fr/api/v4/stations/' + this.props.item.cabinet.name, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + this.props.options.token
        },
        body: JSON.stringify({
          location_name: this.state.location_name,
          address: this.state.address
        }),
        credentials: "omit"
      }).then((res) => res.json())
        .then((data) => {
          if (data.success) {
            alert("Address updated")
          } else {
            alert("Address not updated")
          }
        })
    } else {
      alert("Please fill in address and location name.")
    }
  }

  updateInputValue = (evt) => {
    this.setState({
      address: evt.target.value
    });
  }

  updateInputValue2 = (evt) => {
    this.setState({
      location_name: evt.target.value
    });
  }

  updateInputValue3 = (evt) => {
    this.setState({
      slot: evt.target.value
    });
  }

  verifyStripeCard = () => {
    if (this.props.item.stripe_customerId === "") {
      return "No"
    }

    return "Yes"
  }

  checkItemType = () => {

  }

  render() {
    if (this.props.itemRetrived === "Stations") {
      this.link = "about/cabinets/t/"
      return (
        <tr key={this.props.item.cabinet.name}>
          <td>
            <Link to={this.link + this.props.item.cabinet.name} onClick={() => { this.props.selectedItem(this.props.item) }}>
              {this.props.item.cabinet.name}
            </Link>
          </td>
          <td>{this.props.item.cabinet.location_name}</td>
          <td>{new Date(this.props.item.cabinet.createdAt).toDateString()}</td>
          <td>{this.props.item.history.length}</td>
          <td>{ this.props.item.cabinet.location.city === undefined || this.props.item.cabinet.location.city === "" ? "Update Cabinet address." :  this.props.item.cabinet.location.city }</td>
          <td>{this.verifyCabinetOnlineStatus(this.props.item.cabinet.lastSeen)}</td>
          <td>{this.props.item.cabinet.powerBanks}</td>
          <td>{new Date(this.props.item.cabinet.lastSeen).toDateString()}</td>
        </tr>
      )
    } else if (this.props.itemRetrived === "Promo Code" || this.props.itemRetrived === "discounts") {
      return (
        <tr key={this.props.item.code} onClick={() => { this.props.selectedItem(this.props.item) }}>
          <td>{new Date().toDateString()}</td>
          <td>{this.props.item.code}</td>
          <td>{this.props.item.discountCondition}</td>
          <td>{this.props.item.redeems.length}</td>
          <td>{this.props.item.credits}</td>
        </tr>
      )
    } else {
      this.link = "about/users/t/"
      return (
        <tr key={this.props.item.email}>
          <td>
            <Link to={this.link + this.props.item.name} onClick={() => { this.props.selectedItem(this.props.item) }}>
              {this.props.item.name}
            </Link>
          </td>
          <td>{this.props.item.email}</td>
          <td>{this.props.item.phone_num}</td>
          <td>{new Date(this.props.item.createdAt).toDateString()}</td>
          <td>{this.verifyStripeCard()}</td>
        </tr>
      )
    }
  }
}