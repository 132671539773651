import React, { Component } from 'react';
import Calendar from 'react-calendar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import CabinetsMap from "./components/CabinetsMap"
import DashboardInfo from "./components/DashboardInfo"
import UserHistory from "./components/History"
import Users from './components/Users';
import Login from './components/Login';
import ResetToken from './components/ResetToken';

/**************************** Navigation *******************************/
import NavBar from './components/Navigation/NavBar';
import SideNavBar from './components/Navigation/SideNavBar';

/**************************** Views ************************************/
import UserView from './components/views/userView';
import CabinetsView from './components/views/cabinet/cabinetsView';
import CabinetsInfo from './components/views/cabinet/cabinetsInfo';
import DiscountView from './components/views/discounts/discountView';

/**************************** Utils ************************************/

const MOMENT = require('moment');
const Cookies = require('js-cookie');

export default class App extends React.Component {
  state = {
    token: undefined,
    selectedItem: undefined,
    loggedInUser: undefined
  }

  constructor() {
    super()

    this.getUser()
  }

  getUser = async () => {
    const tok = await Cookies.get('token');
    if (tok) this.setState({ token: tok })
  }

  getHome = () => {
    if (window.location.href !== "https://plugheur.fr/" && window.location.hostname !== "www.plugheur.fr") {
      if (this.state.token !== undefined) {
        return (
          <Dashboard saveLoggedInUser={(user) => { this.setState({ loggedInUser: user }) }} saveSelectedItem={this.saveSelectedItem} getToken={(token) => { this.setState({ token }) }} options={{ token: this.state.token }} />
        )
      } else {
        return (
          <Login getToken={(token) => { this.setState({ token }); Cookies.set('token', token, { expires: 1 }); }} />
        )
      }
    } else {
      window.location.replace("https://plugheur.com/")
    }
  }

  redirect = () => {
    return <Redirect to='/' />
  }

  userView = ({ match }) => {
    return (
      <div>
        <ResetToken id={match.params.resetToken} />
      </div>
    )
  }

  saveSelectedItem = (e) => {
    this.setState({ selectedItem: e })
  }

  userViewFull = () => {
    return (
      <div style={{ width: "100%" }}>
        <NavBar itemGroupSelected={"Users"} />
        <SideNavBar loggedUser={this.state.loggedInUser} setPropItem={(item) => { window.location.replace("/") }} />

        <div className="mainInfo">
          <Users item={this.state.selectedItem} options={{ token: this.state.token }} />
        </div>
      </div>
    )
  }

  cabinetViewFull = ({ match }) => {
    return (
      <div style={{ width: "100%" }}>
        <NavBar itemGroupSelected={"Users"} />
        <SideNavBar loggedUser={this.state.loggedInUser} setPropItem={(item) => { window.location.replace("/") }} />

        <div className="mainInfo">
          {/* <Users item={this.state.selectedItem} options={{ token: this.state.token }} /> */}
          <CabinetsInfo options={{ token: this.state.token }} item={match.params.username} />
        </div>
      </div>
    )
  }

  resturnUesrHistory = ({ match }) => {
    return (
      <div style={{ width: "100%" }}>
        <NavBar itemGroupSelected={"Users"} />
        <SideNavBar loggedUser={this.state.loggedInUser} setPropItem={(item) => { window.location.replace("/") }} />

        <div className="mainInfo">
          <UserHistory options={{ token: this.state.token }} history={match.params.user} viewSelected={3} viewSelectedTitle={"Total Rentals"} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Router>
          <Switch>
            <Route exact path="/about/users/t/:username" component={this.userViewFull} />
            <Route exact path="/about/cabinets/t/:username" component={this.cabinetViewFull} />
            <Route exact path="/resetpassword/:resetToken" component={this.userView} />
            <Route exact path="/history/:user" component={this.resturnUesrHistory} />
            <Route exact path="/">
              {this.getHome()}
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export class Dashboard extends React.Component {

  state = {
    loggedInUser: undefined,
    users: [],
    usersView: [],
    cabinets: [],
    cabinetsView: [],
    announcement: {
      header: "-",
      msg: "-"
    },
    itemRetrived: "Stations",
    itemType: "list",
    discounts: [],
    search: "",
    onlineStations: [],
    offlineStations: [],
    historyView: 1,
    historyViewTitle: "Power Bank Usage"
  }

  constructor(props) {
    super(props)

    this.getUser()
    this.getUsers()
    this.getDiscounts()
  }

  getUser = async () => {
    await fetch('https://plugheur.fr/api/v4/check/me', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + this.props.options.token
      },
      credentials: 'omit'
    }).then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.props.saveLoggedInUser(data.data)
          this.setState({ loggedInUser: data.data })
        }
      })
  }

  createUsersCsv = async () => {
    var msg = "EMAIL,NOM,PRENOM,SMS,DateRegistered\n"
    var msgCabinet = "StationName,LocationName,InstalationDate,NumberofRentals,OnlineStatus,Powerbanks\n"
    var users = this.state.users.reverse()
    var cabinets = this.state.cabinets

    for (var u in users) {
      if (u < 800) {
        msg += users[u].email + "," + users[u].name + "," + users[u].surname + "," + users[u].phone_num + "," + new Date(users[u].createdAt).toLocaleDateString() + "\n"
      }
    }

    for (var y in cabinets) {
      msgCabinet += cabinets[y].cabinet.name + "," + cabinets[y].cabinet.location_name + "," + new Date(cabinets[y].cabinet.createdAt).toLocaleDateString() + "," + cabinets[y].history.length + "," + this.verifyCabinetOnlineStatus(cabinets[y].cabinet.lastSeen) + "," + cabinets[y].cabinet.powerBanks + "\n"
    }

    await fetch('https://plugheur.fr/api/v4/check/createUsersCsv', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + this.props.options.token
      },
      body: JSON.stringify({
        users: msg,
        cabinets: msgCabinet
      }),
      credentials: 'omit'
    }).then((res) => res.json())
      .then((data) => {
      })
  }

  getUsers = async () => {
    await fetch('https://plugheur.fr/api/v4/registers', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + this.props.options.token,
        'Access-Control-Allow-Credentials': true
      },
      credentials: 'omit'
    }).then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.setState({ users: data.data, usersView: data.data }, () => {
            this.getCabinets()
          })
        } else {
          this.props.getToken(undefined)
          alert("Not admin")
        }
      })
  }

  getCabinets = async () => {
    await fetch('https://plugheur.fr/api/v4/stations', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + this.props.options.token
      },
      credentials: 'omit'
    }).then((res) => res.json())
      .then((data) => {
        if (data.success) {
          var obj = {
            cabinetsView: data.data
          }

          if (this.state.cabinets.length === 0) {
            obj = {
              cabinets: data.data,
              cabinetsView: data.data
            }
          }

          this.setState(obj, () => {
            this.createUsersCsv()
            // setInterval(() => {
            //   this.getCabinets()
            // }, 1000 * 60)
          })
        } else {
          this.props.getToken(undefined)
          alert("Not admin")
        }
      })
  }

  getAnnouncements = async () => {
    await fetch('https://plugheur.fr/api/v4/stations', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'omit'
    }).then((res) => res.json())
      .then((data) => {
        if (data.success) {
          for (let i = 0; i < data.count; i++) {
            this.setState({ announcement: { header: data.data[i].global_announcement_heading, msg: data.data[i].global_announcement_message } })
          }
        }
      })
      .catch((err) => console.log(err))
  }

  getDiscounts = async () => {
    await fetch('https://plugheur.fr/api/v4/check/discounts', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + this.props.options.token
      },
      credentials: 'omit'
    }).then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.setState({ discounts: data.data })
        }
      })
      .catch((err) => console.log(err))
  }

  change = (event) => {
    this.setState({ discountCondition: event.target.value });
  }

  verifyView = () => {
    if (this.state.itemRetrived === "Users") {
      return (
        <div className="mainInfo">
          <div className="mainControlButtons">
            {this.controlButtonInfo(1)}
            {this.controlButtonInfo(2)}
            {this.controlButtonInfo(3)}
            {this.controlButtonInfo(4)}
          </div>
          <UserView
            options={this.props.options}
            selectedItem={this.props.saveSelectedItem}
            users={this.state.users}
            usersView={this.state.usersView}
            search={this.search}
            sort={this.sort}
          />
        </div>
      )
    } else if (this.state.itemRetrived === "Dashboard") {
      return (
        <div className="mainInfo">
          <div className="mainControlButtons">
            {this.controlButtonInfo(1)}
            {this.controlButtonInfo(2)}
            {this.controlButtonInfo(3)}
            {this.controlButtonInfo(4)}
          </div>
          <div className="mainInfoContainer">

            <DashboardInfo options={this.props.options} />
          </div>
        </div>
      )
    } else if (this.state.itemRetrived === "Stations") {
      if (this.state.itemType === "map") {
        return (
          <div className="mainInfo">
            <div className="mainControlButtons">
              {this.controlButtonInfo(1)}
              {this.controlButtonInfo(2)}
              {this.controlButtonInfo(3)}
              {this.controlButtonInfo(4)}
            </div>
            <div className="mainInfoContainer">
              <div align="center">
                <span style={{ marginRight: 20, cursor: "pointer" }} onClick={() => { this.setState({ itemType: "map" }) }}>Map</span>
                <span style={{ marginRight: 20, cursor: "pointer" }} onClick={() => { this.setState({ itemType: "list" }) }}>List</span>
              </div>
              <CabinetsMap Cabinets={this.state.cabinets} options={this.props.options} />
            </div>
          </div>
        )
      }

      return (
        <div className="mainInfo">
          <div className="mainControlButtons">
            {this.controlButtonInfo(1)}
            {this.controlButtonInfo(2)}
            {this.controlButtonInfo(3)}
            {this.controlButtonInfo(4)}
          </div>
          <CabinetsView
            options={this.props.options}
            selectedItem={this.props.saveSelectedItem}
            users={this.state.cabinets}
            usersView={this.state.cabinetsView}
            search={this.search}
            sort={this.sort}
            setCabinetsViewType={(type) => { this.setState({ itemType: type }) }}
          />
        </div>
      )
    } else if (this.state.itemRetrived === "Rentals") {
      return (
        <div className="mainInfo">
          <UserHistory options={this.props.options} viewSelected={this.state.historyView} viewSelectedTitle={this.state.historyViewTitle} />
        </div>
      )
    } else {
      return (
        <div className="mainInfo">
          <DiscountView
            options={this.props.options}
            selectedItem={this.props.saveSelectedItem}
            discountList={this.state.discounts}
            usersView={this.state.discounts}
            search={this.search}
            sort={this.sort}
            updateList={this.getDiscounts}
          />
        </div>
      )
    }
  }

  search = evt => {
    this.setState({ search: evt.target.value.toString() }, () => {
      var newDeviceList = []

      var allCabinets = this.state.usersView
      if (this.state.itemRetrived === "cabinets" || this.state.itemRetrived === "Stations") {
        allCabinets = this.state.cabinetsView
      } else if (this.state.itemRetrived === "Promo Code") {
        allCabinets = this.state.discounts
      }

      //filter
      for (var device of allCabinets) {
        if (this.state.itemRetrived === "cabinets" || this.state.itemRetrived === "Stations") {
          if (this.state.search.length > 0) {
            if (device.cabinet.name.toString().toLowerCase().includes(this.state.search.toLowerCase())) {
              newDeviceList.push(device)
            } else if (device.cabinet.location_name.toString().toLowerCase().includes(this.state.search.toLowerCase())) {
              newDeviceList.push(device)
            }
          } else {
            newDeviceList.push(device)
          }
        } else if (this.state.itemRetrived === "Promo Code") {
          if (this.state.search.length > 0) {
            if (device.code.toString().toLowerCase().includes(this.state.search.toLowerCase())) {
              newDeviceList.push(device)
            }
          } else {
            newDeviceList.push(device)
          }
        } else {
          if (this.state.search.length > 0) {
            if (device.name.toString().toLowerCase().includes(this.state.search.toLowerCase())) {
              newDeviceList.push(device)
            } else if (device.email.toString().toLowerCase().includes(this.state.search.toLowerCase())) {
              newDeviceList.push(device)
            }
          } else {
            newDeviceList.push(device)
          }
        }
      }
      //end filter

      if (newDeviceList.length === 0) {
        newDeviceList = allCabinets
      }

      if (this.state.itemRetrived === "cabinets" || this.state.itemRetrived === "Stations") {
        this.setState({ cabinets: newDeviceList })
      } else if (this.state.itemRetrived === "Promo Code") {
        this.setState({ discounts: newDeviceList })
      } else {
        this.setState({ users: newDeviceList })
      }
    })
  }

  TimeDiff = (startTime, endTime, format) => {
    startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
    endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
    return endTime.diff(startTime, format, true);
  }

  verifyCabinetOnlineStatus = (date) => {
    if (parseInt(this.TimeDiff(new Date(date), new Date(), 'minutes')) < 1) {
      return "Online"
    }

    return "Offline"
  }

  sort = (list, value, type) => {
    var sortedList = []
    var oldList = this.state[list]
    if (list === "discounts") {
      if (value === "redeems") {
        sortedList = oldList.sort((a, b) => {
          return (
            a[value].length > b[value].length ? (1 * type) : (-1 * type)
          )
        })
      } else {
        sortedList = oldList.sort((a, b) => {
          return (
            a[value] > b[value] ? (1 * type) : (-1 * type)
          )
        })
      }
    } else if (list !== "users") {
      if (value === "date") {
        sortedList = oldList.sort((a, b) => {
          if (new Date(a["cabinet"]["createdAt"]) > new Date(b["cabinet"]["createdAt"])) {
            return 1
          } else {
            return -1
          }
        })
      } else if (value === "dateSeen") {
        sortedList = oldList.sort((a, b) => {
          if (new Date(a["cabinet"]["lastSeen"]) > new Date(b["cabinet"]["lastSeen"])) {
            return 1
          } else {
            return -1
          }
        })
      } else if (value === "rentals") {
        sortedList = oldList.sort((a, b) => {
          return (
            a["history"].length > b["history"].length ? (1 * type) : (-1 * type)
          )
        })
      } else if (value === "online") {
        sortedList = oldList.sort((a, b) => {
          if (this.verifyCabinetOnlineStatus(a["cabinet"]["lastSeen"]) > this.verifyCabinetOnlineStatus(b["cabinet"]["lastSeen"])) {
            return (1 * type)
          } else {
            return (-1 * type)
          }
        })
      } else {
        sortedList = oldList.sort((a, b) => {
          return (
            a["cabinet"][value] > b["cabinet"][value] ? (1 * type) : (-1 * type)
          )
        })
      }
    } else {
      if (value === "date") {
        sortedList = oldList.sort((a, b) => {
          if (new Date(a["createdAt"]) > new Date(b["createdAt"])) {
            return 1
          } else {
            return -1
          }
        })
      } else if (value === "card") {
        sortedList = oldList.sort((a, b) => {
          if (a.stripe_customerId === "") {
            a = false
          } else {
            a = true
          }

          if (b.stripe_customerId === "") {
            b = false
          } else {
            b = true
          }

          return (
            a > b ? (1 * type) : (-1 * type)
          )
        })

      } else {
        sortedList = oldList.sort((a, b) => {
          return (
            a[value] > b[value] ? (1 * type) : (-1 * type)
          )
        })
      }
    }

    if (list === "users") {
      this.setState({ users: sortedList })
    } else if (list === "discounts") {
      this.setState({ discounts: sortedList })
    } else {
      this.setState({ cabinets: sortedList })
    }
  }

  getStat = (stat, cycle, statType) => {
    var info = this.state[stat]
    var days = 1
    var count = 0

    var onlineStations = []
    var offlineStations = []

    if (cycle === "weekly") {
      days = 7
    } else if (cycle === "monthly") {
      days = 30
    }

    for (var x in info) {
      if (stat === "usersView") {
        if (new Date(info[x][statType]).toDateString() == new Date().toDateString()) {
          count++
        }
      } else if (stat === "cabinetsView") {
        if (parseInt(this.TimeDiff(new Date(info[x].cabinet[statType]), new Date(), cycle)) <= days) {
          onlineStations.push(info[x])
          count++
        } else {
          offlineStations.push(info[x])
        }
      }
    }

    if (this.state.offlineStations.length === 0 && this.state.onlineStations.length === 0) {
      this.setState({
        onlineStations,
        offlineStations
      })
    }

    return count;
  }

  controlButtonInfo = (button) => {
    if (this.state.itemRetrived === "Users") {
      if (button === 1) {
        return (
          <div className="mainControlButton">
            <div className="mainControlButtonInfo">
              <div>{this.state.usersView.length}</div>
              <div>Total Users</div>
            </div>
          </div>
        )
      } else if (button === 2) {
        return (
          <div className="mainControlButton">
            <div className="mainControlButtonInfo">
              <div>{this.getStat("usersView", "days", "createdAt")}</div>
              <div>New Users Today</div>
            </div>
          </div>
        )
      } else if (button === 3) {
        return (
          <div className="mainControlButton">
            <div className="mainControlButtonInfo">
              <div>{this.getStat("usersView", "days", "last_seen")}</div>
              <div>Online Users Today</div>
            </div>
          </div>
        )
      } else {
        return null
      }

    } else if (this.state.itemRetrived === "Dashboard") {
      return (
        <div className="mainControlButton">
          <div className="mainControlButtonInfo">
            <div>C...</div>
            <div>New Users Today</div>
          </div>
        </div>
      )
    } else if (this.state.itemRetrived === "Stations") {
      if (button === 1) {
        return (
          <div className="mainControlButton">
            <div className="mainControlButtonInfo" onClick={() => { this.setState({ cabinets: this.state.cabinetsView }) }}>
              <div>{this.state.cabinetsView.length}</div>
              <div>Total of Stations</div>
            </div>
          </div>
        )
      } else if (button === 2) {
        return (
          <div className="mainControlButton">
            <div className="mainControlButtonInfo" onClick={() => { this.setState({ cabinets: this.state.onlineStations }) }}>
              <div>{this.getStat("cabinetsView", "minutes", "lastSeen")}</div>
              <div>Online Stations</div>
            </div>
          </div>
        )
      } else if (button === 3) {
        return (
          <div className="mainControlButton">
            <div className="mainControlButtonInfo" onClick={() => { this.setState({ cabinets: this.state.offlineStations }) }}>
              <div>{this.state.cabinetsView.length - this.getStat("cabinetsView", "minutes", "lastSeen")}</div>
              <div>Offline Stations</div>
            </div>
          </div>
        )
      } else {
        return null
      }
    } else {
      return (
        <div className="mainControlButton">
          <div className="mainControlButtonInfo">
            <div>C...</div>
            <div>Ofline Stations</div>
          </div>
        </div>
      )
    }
  }

  render() {
    if (this.state.cabinets.length > 0 && this.state.users.length > 0 && this.state.announcement.header !== undefined) {
      return (
        <div style={{ width: "100%" }}>
          <NavBar itemGroupSelected={this.state.itemRetrived} />
          <SideNavBar loggedUser={this.state.loggedInUser} setPropItem={(item) => { this.setState({ itemRetrived: item }) }} />

          {
            this.verifyView()
          }
        </div>
      );
    } else {
      return (
        <div style={{ height: "100vh" }}>
          <div className="loader"></div>
        </div>
      )
    }
  }
}

export class MyCalendar extends Component {
  state = {
    date: new Date(),
  }

  onChange = (date) => {
    // console.log(new Date(date).getUTCDate())
    this.setState({ date })
  }

  render() {
    return (
      <div>
        <Calendar
          onChange={this.onChange}
          value={this.state.date}
        />
      </div>
    );
  }
}
export class ItemSelection extends Component {

  link = "about/"
  state = {
    address: "",
    location_name: "",
    slot: null,
    search: ""
  }

  popBank = async () => {
    if (this.state.slot !== null) {
      await fetch('https://plugheur.fr/api/v4/stations/popup', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + this.props.options.token
        },
        body: JSON.stringify({
          cabinetID: this.props.item.cabinet.name,
          i: "eye",
          slot: this.state.slot.toString()
        }),
        credentials: 'omit'
      }).then((res) => res.json())
        .then((data) => {
          if (data === "Done") {
            alert("Success")
          } else {
            alert("Failed")
          }
        })
    } else {
      alert("Failed, slot is required.")
    }
  }

  updateAddress = async () => {
    // console.log(this.state)
    if (this.state.address !== "" && this.state.location_name !== "") {
      await fetch('https://plugheur.fr/api/v4/stations/' + this.props.item.cabinet.name, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + this.props.options.token
        },
        body: JSON.stringify({
          location_name: this.state.location_name,
          address: this.state.address
        }),
        credentials: 'omit'
      }).then((res) => res.json())
        .then((data) => {
          if (data.success) {
            alert("Address updated")
          } else {
            alert("Address not updated")
          }
        })
    } else {
      alert("Please fill in address and location name.")
    }
  }

  updateInputValue = (evt) => {
    this.setState({
      address: evt.target.value
    });
  }

  updateInputValue2 = (evt) => {
    this.setState({
      location_name: evt.target.value
    });
  }

  updateInputValue3 = (evt) => {
    this.setState({
      slot: evt.target.value
    });
  }

  verifyStripeCard = () => {
    if (this.props.item.stripe_customerId === "") {
      return "No"
    }

    return "Yes"
  }

  checkItemType = () => {

  }

  render() {
    if (this.props.itemRetrived === "Stations") {
      this.link = "about/cabinets/t/"
      return (
        <div style={{ flexDirection: "row", padding: 10 }}>
          <div style={{ width: "100%" }}>
            <Link to={this.link + this.props.item.cabinet.name}>
              {this.props.item.cabinet.name}
            </Link>
            <div style={{ padding: 50 }}>
              <input
                type="text"
                placeholder={this.props.item.cabinet.address}
                width={100}
                height={50}
                onChange={this.updateInputValue}>
              </input>
              <div>
                <input
                  type="text"
                  placeholder={this.props.item.cabinet.location_name}
                  width={100}
                  height={50}
                  onChange={this.updateInputValue2}>
                </input>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Slot"
                  width={100}
                  height={50}
                  onChange={this.updateInputValue3}>
                </input>
              </div>
              <div>
                Total Rentals: {this.props.item.history.length}
              </div>
              <button onClick={this.updateAddress}>Update Address</button>
              <button onClick={this.popBank}>Pop Bank</button>
            </div>
          </div>
        </div>
      )
    } else if (this.props.itemRetrived === "discounts" || this.props.itemRetrived === "Promo Code") {
      return (
        <div style={{ flexDirection: "row", padding: 10 }}>
          <div style={{ width: "100%" }}>
            <h2>{this.props.item.code}</h2>
            <div style={{ padding: 50 }}>
              <input
                type="text"
                placeholder={"Redeems - " + this.props.item.redeems.length}
                width={100}
                height={50}
                onChange={null}>
              </input>
              <div>
                <input
                  type="text"
                  placeholder={"Credits - " + this.props.item.credits}
                  width={100}
                  height={50}
                  onChange={null}>
                </input>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      this.link = "about/users/t/"
      return (
        <tr key={this.props.item.email}>
          <td>
            <Link to={this.link + this.props.item.name} onClick={() => { this.props.selectedItem(this.props.item) }}>
              {this.props.item.name}
            </Link>
          </td>
          <td>{this.props.item.email}</td>
          <td>{this.props.item.phone_num}</td>
          <td>{new Date(this.props.item.createdAt).toDateString()}</td>
          <td>{this.verifyStripeCard()}</td>
        </tr>
      )
    }
  }
}
