import React from "react";
import { ResponsiveBar } from '@nivo/bar'
const MOMENT = require('moment');

export default class DashboardInfo extends React.PureComponent {

    state = {
        cabinetInfo: undefined,
        userHistory: undefined,
        customerInfo: undefined,
        newUserStat: "weekly",
        lastSeenUserStat: "weekly",
        lastSeenCabinetStat: "weekly",
        graphData: undefined
    }

    constructor(props) {
        super(props)
        this.getCabinets()
        this.getUsers()
    }

    getUsers = async () => {
        await fetch('https://plugheur.fr/api/v4/registers', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                // console.log("Users", data)
                if (data.success) {
                    this.setState({ customerInfo: data.data }, () => {
                        this.createChartData()
                    })
                } else {
                    this.props.getToken(undefined)
                    alert("Not admin")
                }
            })
    }

    createChartData = () => {
        var finalData = []

        finalData.push({
            "type": "DAILY",
            "New Users": this.getStat("customerInfo", "daily", "createdAt"),
            "Users Last Seen": this.getStat("customerInfo", "daily", "last_seen"),
            "Cabinets Last Seen": this.getStat("cabinetInfo", "daily", "lastSeen")
        })
        finalData.push({
            "type": "WEEKLY",
            "New Users": this.getStat("customerInfo", "weekly", "createdAt"),
            "Users Last Seen": this.getStat("customerInfo", "weekly", "last_seen"),
            "Cabinets Last Seen": this.getStat("cabinetInfo", "weekly", "lastSeen")
        })
        finalData.push({
            "type": "MONTHLY",
            "New Users": this.getStat("customerInfo", "monthly", "createdAt"),
            "Users Last Seen": this.getStat("customerInfo", "monthly", "last_seen"),
            "Cabinets Last Seen": this.getStat("cabinetInfo", "monthly", "lastSeen")
        })
        this.setState({ graphData: finalData })
    }

    TimeDiff = (startTime, endTime, format) => {
        startTime = MOMENT(startTime, 'YYYY-MM-DD HH:mm:ss');
        endTime = MOMENT(endTime, 'YYYY-MM-DD HH:mm:ss');
        return endTime.diff(startTime, format, true);
    }

    getCabinets = async () => {
        await fetch('https://plugheur.fr/api/v4/stations', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.options.token
            },
            credentials: "omit"
        }).then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    this.setState({ cabinetInfo: data.data })
                } else {
                    this.props.getToken(undefined)
                    alert("Not admin")
                }
            })
    }

    getStat = (stat, cycle, statType) => {
        var info = this.state[stat]
        var days = 1
        var count = 0

        if (cycle === "weekly") {
            days = 7
        } else if (cycle === "monthly") {
            days = 30
        }

        for (var x in info) {
            if (stat === "customerInfo") {
                if (parseInt(this.TimeDiff(new Date(info[x][statType]), new Date(), 'days')) <= days) {
                    count++
                }
            } else if (stat === "cabinetInfo") {
                if (parseInt(this.TimeDiff(new Date(info[x].cabinet[statType]), new Date(), 'days')) <= days) {
                    count++
                }
            }
        }

        return count;
    }

    home = () => {
        if (this.state.cabinetInfo !== undefined && this.state.customerInfo !== undefined && this.state.graphData !== undefined) {
            // console.log(this.state.cabinetInfo)
            return (
                <div className="graphStyle">
                    <ResponsiveBar
                        data={this.state.graphData}
                        indexBy="type"
                        keys={['New Users', 'Users Last Seen', 'Cabinets Last Seen']}
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        colors={{ scheme: 'dark2' }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'TYPE',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'NUMBER',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: "right",
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div>
            )
        } else {
            return (
                <div style={{ height: "100vh" }}>
                    <div className="loader"></div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                {this.home()}
            </div>
        )
    }
}
